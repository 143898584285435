import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { fetchLeaveDaysBalance } from '../../../../store/dispatchers'

const LeaveSelect = props => {
  const handleChange = arg => {
    // calls setFieldValue and manually update values.leavetype
    props.onChange('leavetype', arg)
    props.dispatch(fetchLeaveDaysBalance(props.id, arg.value))
  }

  const handleBlur = () => {
    // calls setFieldTouched and manually update touched.leavetype
    props.onBlur('leavetype', true)
  }

  const populateLeave = (arg = []) => {
    const leaveList = []
    arg.filter(
      (L) => (L.gender === props.user_gender || L.gender === 'B')
    ).map(
      (L, i) => leaveList.push({ value: L.id, label: L.type_name })
    )
    return leaveList
  }

  return (
    <div>
      <Select
        className='leave-type'
        options={populateLeave(props.leave)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        placeholder='Select a leave option...'
      />
      {!!props.error && props.touched &&
        (<span className='invalid-feedback'>{props.error}</span>)}
    </div>
  )
}

LeaveSelect.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  user_gender: PropTypes.string,
  leave: PropTypes.array,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.any,
  touched: PropTypes.any
}

export default LeaveSelect
