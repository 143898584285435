import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { MDBCol, MDBRow, MDBBtn } from 'mdbreact'
import LeaveRequestsList from '../listLeaveRequest'
import LMSForm from '../leaveForm'
import {
  fetchLeaveTypes,
  fetchUserLeaveRequests
} from '../../../../../store/dispatchers'

const MyProfiletabLeavePage = ({ ...props }) => {
  const [modal, setModal] = useState({
    modal2: false
  })

  useEffect(() => {
    props.dispatch(
      fetchLeaveTypes(sessionStorage.getItem('token'))
    )
    // eslint-disable-next-line
  }, [props.user.gender])

  useEffect(() => {
    getUserLeaveRequests()
    // eslint-disable-next-line
  }, [props.user.id])

  const getUserLeaveRequests = () => {
    props.dispatch(
      fetchUserLeaveRequests(sessionStorage.getItem('token'), props.user.id)
    )
  }

  // load request dialog
  const toggle = nr => () => {
    const m = 'modal' + nr.split('@')[0]
    setModal({
      ...modal,
      [m]: !modal[m]
    })
  }

  return (
    <>
      <MDBRow className='leave-point'>
        <MDBCol md='11' className='tab-content'>
          <MDBRow>
            <MDBCol md='9'>
              <h5 className='text-left'>
                Leave Manager
              </h5>
            </MDBCol>
            <MDBCol md='3'>
              <div className='text-right'>
                <MDBBtn
                  color='teal'
                  className='leave-button'
                  name='edit_basic'
                  onClick={toggle(2 + '@' + 0)}
                >
                  Request
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol md='11' className='tab-content leave-requests'>
          <LeaveRequestsList
            id={props.user.id}
            leaveList={props.leave.userLeaveRequests.leaverequests}
          />
        </MDBCol>
      </MDBRow>
      <LMSForm
        {...props}
        modal={modal.modal2}
        id={props.user.id}
        gender={props.user.gender}
        leaveTypes={props.leave.timeOff.leavetypes}
        toggleModal={toggle}
        getleaveList={getUserLeaveRequests}
      />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user_profile_reducer,
  leave: state.leave_reducer
})

export default connect(mapStateToProps)(MyProfiletabLeavePage)
