import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBAlert } from 'mdbreact'
import { allUserNotifications, removeUserNotification } from '../../../store/dispatchers'

const HrMainPanel = ({ name = '', ...props }) => {
  useEffect(() => {
    props.dispatch(
      allUserNotifications()
    )
    // eslint-disable-next-line
  }, [])

  const handleViewNotitification = (id) => {
    props.dispatch(
      removeUserNotification(id)
    )
  }

  const { notifications } = props

  return (
    <MDBCard className='main hr-card card-body'>
      <MDBRow>
        <MDBCol>
          <MDBCardTitle><b>Welcome, {name}</b></MDBCardTitle>
          <div className='card-text'>
            {notifications.user_notifications.length > 0 &&
              <div>
                {notifications.user_notifications.map((n, i) =>
                  <MDBAlert
                    key={i}
                    onClosed={() => handleViewNotitification(n.id)}
                    color={n.status === 'approved' ? 'success' : 'danger'}
                    dismiss
                  >
                    <strong>Notification : </strong> {n.description}.
                  </MDBAlert>
                )}
              </div>}
            {notifications.user_notifications.length < 2 &&
              <span>This HRiS platform is the center for all your
                 activities that concern our Human Resource team.
              </span>}
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  )
}
const mapStateToProps = state => ({ notifications: state.leave_reducer })

export default connect(mapStateToProps)(HrMainPanel)
