import * as Yup from 'yup'

export const createUserSchema = Yup.object({
  given_name: Yup.string('Enter the First Name')
    .required('First name is required'),

  surname: Yup.string('Enter the Last Name')
    .required('Last name is required'),

  phone1: Yup.number('Enter a Phone number')
    .typeError('That is not a valid Phone number')
    .positive('Enter a valid Phone number')
    .integer('Enter only Numbers')
    .min(10, 'Enter atleast 10 digits')
    .required('Phone number is required'),

  primary_email: Yup.string('Enter an email')
    .email('Enter a valid email')
    .required('Email is required'),

  gender: Yup.string()
    .required('Gender is required'),

  join_date: Yup.date()
    .required('Start date is required'),

  position: Yup.string('Enter a position')
    .required('User position is required'),

  department: Yup.string('Enter a department')
    .required('Department is required'),

  user_group: Yup.string()
    .required('User group is required'),

  supervisor: Yup.string()
    .ensure()
    .required('Pick a supervisor')

})
