import React from 'react'
import { Route } from 'react-router-dom'
import ProfileTabsTemplate from './profileTabsTemplate'

const TabRoute = ({ component: Component, ...rest }) =>
// Defined route for components that require inner-tabs menu
  <Route
    {...rest}
    render={
      props => (
        <>
          <Route component={ProfileTabsTemplate} />
          <Component {...props} />
        </>
      )
    }
  />

export default TabRoute
