import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import format from 'date-fns/format'
import RequestLeaveForm from './requestLeaveForm'
import { requestLeaveSchema } from '../validate'
import { leaveRequest } from '../../../../../store/dispatchers'

const LMSForm = ({
  modal,
  id,
  gender,
  leaveTypes = [],
  Leave = [],
  dispatch = f => f,
  toggleModal = f => f,
  getleaveList = f => f
}) => {
  const values = {
    userID: id,
    leavetype: null,
    startDate: '',
    endDate: '',
    reason: ''
  }

  return (
    <Formik
      initialValues={values}
      validationSchema={requestLeaveSchema}
      render={
        (props) =>
          <RequestLeaveForm
            {...props}
            reducer_obj={Leave}
            modal2={modal}
            getId={id}
            getGender={gender}
            getLeavetype={leaveTypes}
            daysLeft={Leave.checkLeaveDays}
            dispatchEvent={dispatch}
            toggleModal={toggleModal}
          />
      }
      onSubmit={
        async (values, actions) => {
          const requestData = {
            ...values,
            user_id: id,
            type_of_leave: values.leavetype.value,
            reason_for_leave: values.reason,
            requested_start_date: format(values.startDate, 'yyyy-MM-dd'),
            requested_end_date: format(values.endDate, 'yyyy-MM-dd')
          }
          await dispatch(leaveRequest(sessionStorage.getItem('token'), requestData))
          await getleaveList()
          setTimeout(() => {
            actions.setSubmitting(false)
            actions.resetForm()
          }, 500)
        }
      }
    />
  )
}

LMSForm.propTypes = {
  modal: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  gender: PropTypes.string,
  leaveTypes: PropTypes.array,
  Leave: PropTypes.object,
  dispatch: PropTypes.func,
  toggleModal: PropTypes.func
}

const mapStateToProps = state => ({
  Leave: state.leave_reducer
})

export default connect(mapStateToProps)(LMSForm)
