import React from 'react'
import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask
} from 'mdbreact'

const HQCarousel = () => {
  return (
    <MDBCarousel
      activeItem={1}
      length={3}
      showControls
      showIndicators
      className='z-depth-1'
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId='1'>
          <MDBView>
            <img
              className='d-block w-100'
              src='https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg'
              alt='First slide'
            />
            <MDBMask overlay='blue-slight' />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className='h1-responsive'>The journey to Nakaseke</h3>
            <h5>A moment to touch the next generation</h5>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId='2'>
          <MDBView>
            <img
              className='d-block w-100'
              src='https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg'
              alt='Second slide'
            />
            <MDBMask overlay='blue-slight' />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className='h1-responsive'>Code Quality is velocity</h3>
            <h5>Reflect on performance to keep improving</h5>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId='3'>
          <MDBView>
            <img
              className='d-block w-100'
              src='https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg'
              alt='Third slide'
            />
            <MDBMask overlay='blue-slight' />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className='h1-responsive'>Spot Light</h3>
            <h5>Hear from Joy in her own words</h5>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  )
};

export default HQCarousel;
