import React, { useState, useEffect } from 'react'
import MetriCards from './sections/metriCards'
import { connect } from 'react-redux'
import Select from 'react-select'
import {
  MDBRow,
  MDBCol,
  MDBNavLink,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact'
import {
  handleUpdateMetricInitialValue,
  handleEditMetricValues
} from '../../../store/actions'
import {
  saveUpdatedMetric,
  fetchMetrics
} from '../../../store/dispatchers'

const DashboardPage = ({ ...props }) => {
  useEffect(() => {
    props.dispatch(
      fetchMetrics(sessionStorage.getItem('token'))
    )
    // eslint-disable-next-line
  }, [])

  const [modal, setModal] = useState({
    active: '5', selectedOption: null, projects: []
  })

  const handleToggleChange = activeItem => e => {
    e.preventDefault()
    props.dispatch(handleUpdateMetricInitialValue('submetric_update', {}))
    setModal({
      ...modal,
      active: activeItem
    })
  }

  const handleModalDialog = (isloaded, data) => e => {
    e.preventDefault()
    const projectsList = []
    if (isloaded && data) {
      data.projects.map((proj, i) => {
        if (!proj.days_spent || data.tag === 're') {
          const project = {
            value: proj.id,
            label: i + '. ' + proj.projectname
          }
          return projectsList.push(project)
        } else {
          return null
        }
      })
      // eslint-disable-next-line
    }

    setModal({
      ...modal,
      active: '5',
      projects: projectsList,
      selectedOption: null
    })

    props.dispatch(handleUpdateMetricInitialValue('submetric_update', {}))
    props.dispatch(handleUpdateMetricInitialValue('new_metric_update', data))
    props.dispatch(handleEditMetricValues('value', ''))
    props.dispatch(handleUpdateMetricInitialValue('updateMetricModal', isloaded))
  }

  const handleOnChange = e => {
    e.preventDefault()
    props.dispatch(handleEditMetricValues(e.target.name, e.target.value))
  }

  const handleSveUpdatedMetric = (id, active) => e => {
    e.preventDefault()
    props.dispatch(saveUpdatedMetric(props.Metric.submetric_update, id, active))
    props.dispatch(handleUpdateMetricInitialValue('updateMetricModal', false))
  }

  const handleSelectProject = option => {
    setModal({
      ...modal,
      selectedOption: option
    })
    props.dispatch(handleEditMetricValues('projectid', option.value))
  }

  const {
    title,
    target_value,
    unit_measure,
    tag,
    id,
    value
  } = props.Metric.new_metric_update

  return (
    <>
      <MDBRow className='mb-4'>
        <MDBCol md='12' xl='12' className='mb-r'>
          <section className='hq-metricview'><br />
            {props.Metric.metrics.map(
              (value, i) =>
                <MDBCol md='3' key={i}>
                  <MDBNavLink
                    to='#!'
                    className='badge badge-light metric-badge'
                  >
                    {value.title}
                  </MDBNavLink>
                  <MetriCards
                    submetrics={value.submetrics}
                    onhandleLoadUpdateDialog={handleModalDialog}
                  />
                </MDBCol>
            )}
          </section>
        </MDBCol>
      </MDBRow>
      <MDBModal
        isOpen={props.Metric.updateMetricModal}
      >
        <MDBModalHeader>
          <MDBRow>
            <MDBCol md='9' className='text-left'>
              Update:&nbsp;
              <span className='indigo-text'>
                Metric {modal.active === '5' ? 'Scores' : 'Details'}
              </span>
            </MDBCol>
            <MDBCol md='3' className='text-right text-muted'>
              <MDBDropdown>
                <MDBDropdownToggle
                  caret
                  color='special'
                  className='dropdown-setting'
                >
                  <MDBIcon icon='cog' size='md' />
                </MDBDropdownToggle>
                <MDBDropdownMenu right className='dropdown-default'>
                  {modal.active === '5'
                    ? (
                      <MDBDropdownItem
                        href='#!'
                        active={modal.active === '6'}
                        onClick={handleToggleChange('6')}
                      >
                        Details
                      </MDBDropdownItem>
                    ) : (
                      <MDBDropdownItem
                        href='#!'
                        active={modal.active === '5'}
                        onClick={handleToggleChange('5')}
                      >
                        Scores
                      </MDBDropdownItem>
                    )}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
          </MDBRow>
        </MDBModalHeader>
        <MDBModalBody className='metrics-edit-content'>
          {modal.active === '5'
            ? (
              <MDBRow>
                <MDBCol sm='12' md='6'>
                  <MDBInput
                    type='text'
                    label={`
                      ${tag === 'he'
                        ? 'How many?'
                        : tag === 're'
                          ? 'How much?'
                          : tag === 'tnps' || tag === 'cnps'
                            ? 'Total'
                            : 'No. of days'}
                    `}
                    size='sm'
                    name='value'
                    value={value}
                    id='new_metric_score'
                    onChange={handleOnChange}
                  />
                </MDBCol>
                <MDBCol sm='12' md='6'>
                  {tag === 'he'
                    ? '' : tag === 're'
                      ? (
                        <>
                          <MDBRow className='empty' />
                          <Select
                            className='manager-select'
                            isDisabled={false}
                            placeholder='Select Project'
                            value={modal.selectedOption}
                            onChange={handleSelectProject}
                            options={modal.projects}
                          />
                        </>
                      ) : tag === 'tnps' || tag === 'cnps'
                        ? <MDBInput
                          type='text'
                          label='No. of participants'
                          size='sm'
                          onChange={handleOnChange}
                          name='participants'
                          id='participants'
                        />
                        : (
                          <>
                            <MDBRow className='empty' />
                            <Select
                              className='manager-select'
                              isDisabled={false}
                              placeholder='Select Project'
                              value={modal.selectedOption}
                              onChange={handleSelectProject}
                              options={modal.projects}
                            />
                          </>
                        )}
                </MDBCol>
              </MDBRow>
            ) : (
              <>
                <MDBRow>
                  <MDBCol sm='12'>
                    <MDBInput
                      type='text'
                      label='Key Metric'
                      size='sm'
                      name='title'
                      value={title}
                      id='metric_title'
                      onChange={handleOnChange}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm='12' md='6'>
                    <MDBInput
                      type='text'
                      label='Target Value'
                      size='sm'
                      name='target_value'
                      value={target_value}
                      id='target_value'
                      onChange={handleOnChange}
                    />
                  </MDBCol>
                  <MDBCol sm='12' md='6'>
                    <MDBInput
                      type='text'
                      label='Unit of Measure'
                      size='sm'
                      name='unit_measure'
                      value={unit_measure}
                      id='unit_measure'
                      onChange={handleOnChange}
                    />
                  </MDBCol>
                </MDBRow>
              </>
            )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color='primary'
            size='sm'
            onClick={handleSveUpdatedMetric(id, modal.active)}
          >
            Save
          </MDBBtn>
          <MDBBtn
            color='danger'
            size='sm'
            onClick={handleModalDialog(false, {})}
          >
            Cancel
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    Metric: state.metrics_reducer
  }
}

export default connect(mapStateToProps)(DashboardPage)
