import React from 'react'
import { Route } from 'react-router-dom'
import HrPageTemplate from './components/hrTemplate'
import HrMainPage from './pages/mainPage'
import HrOtherPages from './pages/otherPages'

const HrHome = (props) => (
  <HrPageTemplate>
    <Route exact path='/hr' component={HrMainPage} />
    <HrOtherPages {...props} />
  </HrPageTemplate>
)

export default HrHome
