import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { MDBRow } from 'mdbreact'
import { fetchMetrics } from '../../../../store/dispatchers'
import ProgressBar from './progressBar'

const TrackerCards = ({ ...props }) => {
  useEffect(() => {
    props.dispatch(
      fetchMetrics(sessionStorage.getItem('token'))
    )
    // eslint-disable-next-line
  }, [])

  const submetrics = []
  props.Metric.metrics.map(
    (value, index) => {
      if (value.submetrics.length > 1) {
        return (
          value.submetrics.map(
            (submetric, i) => submetric.tag === 'tnps' || submetric.tag === 'sp'
              ? submetrics.push(value.submetrics[i]) : null
          )
        )
      } else {
        return submetrics.push(value.submetrics[0])
      }
    })

  return (
    <MDBRow className='mb-4'>
      {submetrics.map(
        (metric, i) => <ProgressBar key={i} {...metric} />
      )}
    </MDBRow>
  )
}

const mapStateToProps = function (state) {
  return {
    Metric: state.metrics_reducer
  }
}

export default connect(mapStateToProps)(TrackerCards)
