import React from 'react'
import { MDBContainer, MDBCol } from 'mdbreact'

const HQReports = () => (
  <MDBContainer>
    <MDBCol className='py-5'>
      <h1>[Human Resource/reports]</h1><br/>
      <h1>See others...</h1>
      <h3>[Marketing/reports]</h3>
      <h3>[Engineering/reports]</h3>
    </MDBCol>
  </MDBContainer>
);

export default HQReports;
