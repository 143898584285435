import * as Yup from 'yup'

export const requestLeaveSchema = Yup.object({

  userID: Yup.number(),

  leavetype: Yup.string()
    .ensure()
    .required('Pick one leave type'),

  startDate: Yup.date()
    .required('Start date is required'),

  endDate: Yup.date()
    .required('End date is required'),

  reason: Yup.string('Please state a reason')
})
