import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact';

const selectedStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "#fffff"
}

const selectedItem = {
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  color: "#fffff"
}

export default class HQNavbar extends Component {

  state = {
    isOpen: false,
    activeItem: '1'
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <MDBNavbar color='deep-purple' dark expand='md'>
        <MDBNavbarBrand>
          <strong className='white-text'>KanzuHQ</strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id='navbarCollapse3' isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <MDBNavLink exact activeStyle={selectedStyle} to='/'>Home</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink activeStyle={selectedStyle} to='/about'>About Kanzu</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div className='d-inline'>Your Spot</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className='dropdown-default'>
                  <MDBDropdownItem>
                    <MDBNavLink activeStyle={selectedItem} to='/hr'><b>HRiS</b></MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBNavLink activeStyle={selectedItem} to='#!'><b>Training</b></MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBNavLink activeStyle={selectedItem} to='/about/stacks'><b>Stacks</b></MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBNavLink className='waves-effect waves-light' to='#!'>
                <MDBIcon fab icon='facebook' />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon='user' />
                </MDBDropdownToggle>
                <MDBDropdownMenu className='dropdown-default'>
                  <MDBDropdownItem>
                    <MDBNavLink activeStyle={selectedItem} to={{
                      pathname:'/profile', state: {
                        username: sessionStorage.getItem('name'),
                      }
                    }}><b>Profile</b></MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBNavLink activeStyle={selectedItem} to='/logout'><b>Log Out</b></MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    )
  }
};

