import React from 'react'
import { Field } from 'formik'
import { RadioButton } from './components/radioButton'
import { RadioButtonGroup } from './components/radioButtonGroup'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalBody,
  MDBInput,
  MDBBtn
} from 'mdbreact'

const CreateUserForm = props => {
  const {
    values: {
      given_name,
      surname,
      phone1,
      primary_email,
      gender,
      join_date,
      position,
      department,
      user_group,
      supervisor
    },
    user = {},
    dirty,
    errors,
    touched,
    handleReset,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    modal5,
    toggleModal
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  };

  const populateOptions=(arg)=>{
    const supervisors=[]
    arg.map((item, i)=>
      supervisors.push({ value: item.id, label: item.names.given_name+ ' '+ item.names.middle_name +' '+item.names.surname}) 
    )
    return supervisors
  }

  const handleSelectChange = arg => {
    // calls setFieldValue and manually update values.leavetype
    setFieldValue('supervisor', arg)
  }

  const handleSelectBlur = () => {
    // calls setFieldTouched and manually update touched.leavetype
    setFieldTouched('supervisor', true)
  }

  return (
    <MDBModal
      backdrop
      isOpen={modal5}
      toggle={toggleModal(5 + '@' + 0)}
      className='hq-add-user'
      md='12'
      id='user-form'
    >
      <form onSubmit={handleSubmit}>
        <MDBModalHeader
          toggle={toggleModal(5 + '@' + 0)}
        >
          Fill this form...
        </MDBModalHeader>
        <MDBModalBody className='tab-content'>
          <MDBRow>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='text'
                className={touched.given_name && errors.given_name ? 'is-invalid' : ''}
                label='First Name'
                size='sm'
                name='given_name'
                value={given_name}
                id='given_name'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'given_name')}
                error={touched.given_name && Boolean(errors.given_name)}
              />
              {touched.given_name && errors.given_name &&
                <span className='invalid-feedback'>{errors.given_name}</span>}
            </MDBCol>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='text'
                className={touched.surname && errors.surname ? 'is-invalid' : ''}
                label='Last Name'
                size='sm'
                name='surname'
                value={surname}
                id='surname'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'surname')}
                error={touched.surname && Boolean(errors.surname)}
              />
              {touched.surname && errors.surname &&
                <span className='invalid-feedback'>{errors.surname}</span>}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='tel'
                className={touched.phone1 && errors.phone1 ? 'is-invalid' : ''}
                label='Phone Number'
                size='sm'
                name='phone1'
                value={phone1}
                id='phone1'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'phone1')}
                error={touched.phone1 && Boolean(errors.phone1)}
              />
              {touched.phone1 && errors.phone1 &&
                <span className='invalid-feedback'>{errors.phone1}</span>}
            </MDBCol>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='email'
                className={touched.primary_email && errors.primary_email ? 'is-invalid' : ''}
                label='Email Address'
                size='sm'
                name='primary_email'
                value={primary_email}
                id='primary-email'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'primary_email')}
                error={touched.primary_email && Boolean(errors.primary_email)}
              />
              {touched.primary_email && errors.primary_email &&
                <span className='invalid-feedback'>{errors.primary_email}</span>}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='6' md='6'>
              <RadioButtonGroup
                id='gender'
                label='Gender'
                className={touched.gender && errors.gender ? 'is-invalid' : ''}
                value={gender}
                error={errors.gender}
                touched={touched.gender}
              >
                <Field
                  component={RadioButton}
                  name='gender'
                  id='M'
                  label='Male'
                />
                <Field
                  component={RadioButton}
                  name='gender'
                  id='F'
                  label='Female'
                />
              </RadioButtonGroup>
            </MDBCol>
            <MDBCol sm='12' md='6'>
              <span className='datepick'>Joined</span>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                maxDate={new Date()}
                selected={join_date}
                placeholderText='Click to select a date'
                name='join_date'
                value={join_date}
                id='join-date'
                required
                onChange={e => setFieldValue('join_date', e)}
              />
              {touched.join_date && errors.join_date &&
                <span className='invalid-feedback'>{errors.join_date}</span>}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='text'
                className={touched.position && errors.position ? 'is-invalid' : ''}
                label='Role'
                size='sm'
                name='position'
                value={position}
                id='position'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'position')}
                error={touched.position && Boolean(errors.position)}
              />
              {touched.position && errors.position &&
                <span className='invalid-feedback'>{errors.position}</span>}
            </MDBCol>
            <MDBCol sm='6' md='6'>
              <MDBInput
                type='text'
                className={touched.department && errors.department ? 'is-invalid' : ''}
                label='Department'
                size='sm'
                name='department'
                value={department}
                id='department'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'department')}
                error={touched.department && Boolean(errors.department)}
              />
              {touched.department && errors.department &&
                <span className='invalid-feedback'>{errors.department}</span>}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='6' md='6'>
              <RadioButtonGroup
                id='user-group'
                label='User Group'
                className={touched.user_group && errors.user_group ? 'is-invalid' : ''}
                value={user_group}
                error={errors.user_group}
                touched={touched.user_group}
              >
                <Field
                  component={RadioButton}
                  name='user_group'
                  id='user'
                  label='User'
                />
                <Field
                  component={RadioButton}
                  name='user_group'
                  id='admin'
                  label='Admin'
                />
              </RadioButtonGroup>
            </MDBCol>
            <MDBCol sm='6' md='6'>
            <span className='datepick'>Supervisor</span>
              <Select
                className='manager-select'
                isDisabled={false}
                placeholder='Select'
                value={supervisor}
                onChange={handleSelectChange}
                onBlur={handleSelectBlur}
                options={populateOptions(props.users.team)}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            type='submit'
            color='primary'
            size='sm'
            disabled={!isValid || isSubmitting}
            onClick={toggleModal(5 + '@' + 0)}
          >
            {user.hasLoaded &&
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                  role='status'
                />
                <span>&nbsp;</span>
              </>}
            Submit
          </MDBBtn>
          <MDBBtn
            color='danger'
            size='sm'
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            Reset
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  )
}

export default CreateUserForm
