import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, updateUser } from '../../../../store/dispatchers'
import { enableEditUserDetails, handleEditUserDetails } from '../../../../store/actions'
import { MDBInput, MDBCol, MDBRow, MDBBtn } from "mdbreact";

class HQProfileBankInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveUpdate = this.handleSaveUpdate.bind(this);
  }

  handleSaveUpdate = event => {
    event.preventDefault();
    let editted = {
      bank_account: {
        bank_branch: this.props.user.bank_branch,
        bank_name: this.props.user.bank_name,
        acc_number: this.props.user.acc_number,
        acc_name: this.props.user.acc_name
      },
      nin: this.props.user.nin,
      tin: this.props.user.tin,
      nssf_number: this.props.user.nssf,
      salary: { gross_pay: this.props.user.salary, currency: "UGX" }
    }

    this.props.dispatch(
      updateUser(
        editted,
        this.props.user.id,
        this.props.user.isuserupdated,
        'bank',
        this.props.user.username
      )
    );
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.dispatch(enableEditUserDetails(event.target.name))
  }

  handleCancel = (event) => {
    event.preventDefault();
    this.props.dispatch(fetchUser(sessionStorage.getItem('token'), this.props.user.username));
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.dispatch(handleEditUserDetails(event.target.name, event.target.value))
  }

  render() {

    const { user } = this.props

    return (
      <MDBRow>
        <MDBCol md='11' className='tab-content'>
          <MDBRow>
            <MDBCol md='10'>
              <h5 className='text-left'>
                Accounts & More
              </h5><hr />
            </MDBCol>
            <MDBCol md='2'>
              <div className='text-right'>
                {!user.edit_personal &&
                  <MDBBtn
                    color='indigo'
                    className='edit-button'
                    name='edit_personal'
                    onClick={this.handleEdit}
                  >
                    Edit
                  </MDBBtn>
                }
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='Bank Name'
                name='bank_name'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.bank_name
                      ? user.bank_name
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='Bank Branch' 
                name='bank_branch' 
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.bank_branch
                      ? user.bank_branch
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
          </MDBRow>
          <p>Account Details</p>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='Account Name'
                name='acc_name'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.acc_name
                      ? user.acc_name
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='Account Number'
                name='acc_number'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.acc_number
                      ? user.acc_number
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label={
                  user.user[0]
                    ? user.currency
                    : 'Gross Salary'}
                name='salary'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.salary
                      ? user.salary
                      : ''
                    : ''}
                size='sm'
                readOnly={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
          </MDBRow>
          <p>Identification Numbers</p>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='NSSF No'
                name='nssf'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.nssf
                      ? user.nssf
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='Tax Identification Number'
                name='tin'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.tin
                      ? user.tin
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label='National Identification Number'
                name='nin'
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.nin
                      ? user.nin
                      : ''
                    : ''}
                size='sm'
                readOnly={user.readonly}
              />
            </MDBCol>
          </MDBRow>
          {user.edit_personal &&
            <MDBRow>
              <MDBCol md='12'>
                <div className='text-right'>
                  <MDBBtn
                    color='primary'
                    className='action-btn'
                    name='save-personalinfo'
                    onClick={this.handleSaveUpdate}
                  >
                    {user.hasLoaded &&
                      <>
                        <span
                          className='spinner-border spinner-border-sm'
                          aria-hidden='true'
                          role='status'
                        ></span>
                        <span>&nbsp;</span>
                      </>
                    }
                    Save
                  </MDBBtn>
                  <MDBBtn
                    color='danger'
                    className='action-btn'
                    name='cancel-personalinfo'
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          }
        </MDBCol>
      </MDBRow>
    )
  }
};

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HQProfileBankInfo);
