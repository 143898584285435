import React from 'react'
import { Redirect } from 'react-router-dom'
import toastr from 'toastr'

toastr.options = {
  "closeButton": true,
  "progressBar": true,
  "positionClass": "toast-top-left",
  "toastClass": "toastr"
};
  
const HQLogout = () => {
  sessionStorage.removeItem("name")
  sessionStorage.removeItem("token")
  const message = "Logged out, Sucessfully";
   
  return(    
    toastr.success(message), <Redirect to= {{ pathname:"/login" }}/>    
  );
}

export default HQLogout;
