import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBNavLink } from 'mdbreact'
import KC_logo24 from '../../../assets/KC_logo24.png';

export const HQ404 = () => (
  <MDBContainer fluid>
    <MDBRow>
      <MDBCol sm='12' className='hq-404'>
      <div className="full">
        <MDBRow className="bad-gateway-row">
          <MDBCol md="8">            
            <h1 className="h1-responsive mt-3 mb-2">Oops!! Got an error.</h1>
            <h3>The requested URL was not found on this server.</h3>
            <img 
              alt="Error 404" 
              className="img-fluid" 
              height="20px"
              src={KC_logo24}
            />
            <MDBNavLink to="/">Back to Home</MDBNavLink>
          </MDBCol>
          <MDBCol md="4">
            <img 
              alt="Error 404" 
              className="img-fluid" 
              src="https://mdbootstrap.com/img/Others/grafika404-bf.png"
            />
          </MDBCol>
        </MDBRow>
      </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);
