import React from 'react'
import HQCarousel from './components/hqCarousel'
import HQCard from './components/hqCard'
import TrackerCards from '../../AppMetrics/components/pages/sections/trackerCards'
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBNavLink
} from 'mdbreact'

const HQMainPage = () => {
  return (
    <>
      <MDBRow>
        <MDBCol size='12'>
          <HQCarousel />
        </MDBCol>
      </MDBRow>
      <section className='hq-section hq-main'>
        <MDBRow className='d-flex flex-sm-column-reverse flex-md-row'>
          <MDBCol md='1' />
          <MDBCol md='4' className='hq-content-1'>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='2' className='d-none d-md-block pr-0'>
                <MDBIcon icon='flag-checkered' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol sm='12' md='10'>
                <h5 className='font-weight-bold'>Employee of the Month</h5>
                <HQCard />
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='2' className='d-none d-md-block pr-0'>
                <MDBIcon icon='glass-martini' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol sm='12' md='10'>
                <h5 className='font-weight-bold'>Celebrations</h5>
                <p className='grey-text'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Reprehenderit maiores nam, aperiam minima assumenda deleniti
                  hic.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='2' className='d-none d-md-block pr-0'>
                <MDBIcon icon='calendar-check' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol sm='12' md='10'>
                <h5 className='font-weight-bold'>Team Updates</h5>
                <p className='grey-text'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Reprehenderit maiores nam, aperiam minima assumenda deleniti
                  hic.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md='6' className='hq-content-2'>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='1' className='d-none d-md-block'>
                <MDBIcon icon='bullseye' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol sm='12' md='11'>
                <h5 className='font-weight-bold'>Our Numbers</h5>
                <MDBNavLink to='/about/metrics' className='metrics'>
                  <MDBCol md='12'>
                    <TrackerCards />
                  </MDBCol>
                </MDBNavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='1' className='d-none d-md-block'>
                <MDBIcon icon='heart' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol sm='12' md='11'>
                <h5 className='font-weight-bold'>Our Story</h5>
                <p className='grey-text'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Reprehenderit maiores nam, aperiam minima assumenda deleniti
                  hic.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='1' className='d-none d-md-block'>
                <MDBIcon icon='bullhorn' size='2x' className='deep-purple-text' />
              </MDBCol>
              <MDBCol size='11'>
                <h5 className='font-weight-bold'>Upcoming</h5>
                <p className='grey-text'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Reprehenderit maiores nam, aperiam minima assumenda deleniti
                  hic.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md='1' />
        </MDBRow>
      </section>
    </>
  )
}

export default HQMainPage
