import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';
import apiCall from '../../../../apiHelper'
import { HQPageAvatar } from '../../hqPage/components/hqAvatar'
import InputForm from '../../../AppUser/inputForm'
import {fetchAllUsers} from '../../../store/actions'
import {
  MDBDataTable,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBDropdown,
  MDBIcon,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';

class HQTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        columns: [
          {
            label: '#',
            field: 'index',
            sort: 'asc',
            width: 10
          },
          {
            label: 'Profile',
            field: 'pic',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Full Name',
            field: 'name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Gender',
            field: 'gender',
            sort: 'asc',
            width: 50
          },
          {
            label: 'Position & Department',
            field: 'position',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Join Date',
            field: 'date',
            sort: 'asc',
            width: 160
          },
          {
            label: 'Phone',
            field: 'phone1',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Email Address',
            field: 'email',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Manager',
            field: 'manager',
            sort: 'asc',
            width: 100
          },
          sessionStorage.getItem('user_group') === 'admin' ?
            {
              label: 'Manage',
              field: 'btn',
              sort: 'asc',
              width: 100
            } : {}
        ],
        rows: []
      },
      showVoided: false,
      activeItem: '1',
      showPrivate: true,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modal6: false,
      modal7: false,
      user_id: '',
      profile_redirect: false,
      username: ''
    }
  }

  componentDidMount = () => {
    sessionStorage.setItem('isIconTeamClicked', 'False')
    this.getAllUsers(this.state.showPrivate, this.state.showVoided);
  }

  // handle deleting a user
  handleDeleteUser = () => {
    let token = sessionStorage.getItem('token')
    if (this.state.user_id && this.state.user_id !== '0') {
      if (token) {
        apiCall('', token, 'delete', 'users/' + this.state.user_id)
          .then((response) => {
            this.getAllUsers(this.state.showPrivate, this.state.showVoided)
          }).catch(error => { });
      }
    }
    this.setState({
      modal2: false
    })
    sessionStorage.setItem('isIconTeamClicked', 'False')
  }

  handleRestoreUser = () => {
    let action = { action: "restore" };
    let token = sessionStorage.getItem('token')
    if (this.state.user_id && this.state.user_id !== '0') {
      if (token) {
        apiCall(action, token, 'put', 'users/' + this.state.user_id)
          .then((response) => {
            this.getAllUsers(this.state.showPrivate, this.state.showVoided)
          }).catch(error => { });
      }
    }
    this.setState({
      modal3: false
    })
    sessionStorage.setItem('isIconTeamClicked', 'False')
  }

  // load confirm dialog
  toggle = nr => () => {
    let modalNumber = 'modal' + nr.split('@')[0]
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      user_id: nr.split('@')[1]
    })

    sessionStorage.getItem('isIconTeamClicked') !== '' ?
      sessionStorage.getItem('isIconTeamClicked') === 'True' ?
        sessionStorage.setItem('isIconTeamClicked', 'False')
        : sessionStorage.setItem('isIconTeamClicked', 'True')
      : sessionStorage.setItem('isIconTeamClicked', 'True')

  }

  // handle change active or deactivated users
  handleUserStatus = activelink => e => {
    if (activelink !== this.state.activeItem) {
      this.setState({
        activeItem: activelink,
        showVoided: !this.state.showVoided,
        showPrivate: !this.state.showPrivate
      });
      this.getAllUsers(!this.state.showPrivate, !this.state.showVoided);
    }
  }

  // handle view user profile by admin
  handleViewUserProfile = (name, showVoided) => {
    if (sessionStorage.getItem('isIconTeamClicked') === 'False' || sessionStorage.getItem('isIconTeamClicked') === '') {
      if (name === sessionStorage.getItem('name') || sessionStorage.getItem('user_group') === 'admin') {
        if (!showVoided) {
          this.setState({
            username: name,
            profile_redirect: true
          })
        } else {
          this.setState({
            modal7: true
          })
          sessionStorage.setItem('isIconTeamClicked', 'True')
        }
      } else {
        this.setState({
          modal6: true,
          profile_redirect: false
        })
      }
    }
  }

  // get all system users
  getAllUsers = (showPrivate, showVoided) => {
    let token = sessionStorage.getItem('token');

    if (token) {
      apiCall('', token, 'get', 'users/?showPrivate=' + showPrivate + '&showVoided=' + showVoided)
        .then((response) => {
          this.props.dispatch(fetchAllUsers(response.users))
          let users = []
          response.users.map((user, index) => {

            let user_row = {
              index:
                index + 1,
              clickEvent: 
                () => {
                  return this.handleViewUserProfile(user.names.username, showVoided);
                },
              pic:
                <HQPageAvatar
                  prof_pic={user.profile_pic_url && user.profile_pic_url.includes('http') ?
                    (user.profile_pic_url) : ('')}
                />,
              name:
                user.names.given_name + ' ' +
                (user.names.middle_name ? user.names.middle_name : '') + ' ' +
                user.names.surname,
              gender:
                user.gender === 'M' ? 'Male' : 'Female',
              position:
                <div>{user.position}<br />{user.department}</div>,
              date:
                moment(user.join_date).format('DD/MM/YYYY'),
              phone1:
                <div>
                  {user.phone_numbers.primary_phone_number}<br />
                  {user.phone_numbers.secondary_phone_number ?
                    (user.phone_numbers.secondary_phone_number) : ('')}
                </div>,
              email:
                <div>
                  {user.emails.primary_email}<br />
                  {user.emails.secondary_email ?
                    (user.emails.secondary_email) : ('')}
                </div>,
              manager:
                user.manager_names ?
                  (
                    user.manager_names.given_name + ' ' + user.manager_names.surname
                  ) : ('---------'),
              btn:
                <div className="text-center">
                  {
                    sessionStorage.getItem('user_group') === 'admin' ?
                      (
                        <div>
                          {sessionStorage.getItem('user_group') === 'admin' ?
                            (
                              <span className="admin-div">
                                {showVoided ?
                                  (
                                    <MDBIcon
                                      className="red-text"
                                      onClick={this.toggle(3 + '@' + user.id)}
                                      icon="user"
                                      title="Restore User"
                                      size="lg" />
                                  ) :
                                  user.user_group === 'admin' && user.names.username === sessionStorage.getItem('name') ?
                                    (
                                      <MDBIcon
                                        className="text-success"
                                        title="Canot deactivate yourself"
                                        onClick={this.toggle(4 + '@' + user.id)}
                                        icon="user"
                                        size="lg" />
                                    ) : (
                                      <MDBIcon
                                        className="red-text"
                                        title="Deactivate User"
                                        onClick={this.toggle(2 + '@' + user.id)}
                                        icon="user-times"
                                        size="lg" />
                                    )
                                }
                              </span>
                            ) : (
                              <span></span>
                            )
                          }
                        </div>
                      ) : (
                        <span></span>
                      )
                  }
                </div>
            }

            users.push(user_row)
            return users
          });

          this.setState(prevState => ({
            data: {
              ...prevState.data,
              rows: users
            }
          }));

        }).catch(error => { });
    }
  }

  render() {

    const {
      profile_redirect,
      username,
      showPrivate, 
      showVoided,
      activeItem,
      data,
      modal2,
      modal3,  
      modal4,  
      modal5,
      modal6,
      modal7
    } = this.state

    return (
      <>
        {
          profile_redirect 
          ? <Redirect 
              to={{ pathname: '/profile', state: { username: username, } }} 
            /> 
          : null
        }
        <div className='page-table'>
          <MDBRow>
            {sessionStorage.getItem('user_group') === 'admin' ?
              (
                <>
                  <MDBCol md="3">
                    <MDBBtn
                      color="success"
                      disabled={showVoided}
                      onClick={this.toggle(5 + '@' + 0)}
                      className="add-user">
                      Add New User
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol md="6" className="text-center table-title">
                    {
                      !showVoided ?
                        (
                          <h2 className="text-success">Active Users</h2>
                        ) : (
                          <h2 className="text-danger">Deactivated Users</h2>
                        )
                    }
                  </MDBCol>
                  <MDBCol md="3" className="text-right">
                    <MDBDropdown>
                      <MDBDropdownToggle color="special" caret>
                        <MDBIcon icon="cog" size="lg" />
                      </MDBDropdownToggle>
                      <MDBDropdownMenu right className='dropdown-default'>
                        <MDBDropdownItem
                          href='#!'
                          active={activeItem === '1'}
                          onClick={this.handleUserStatus('1')}>
                          Active
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          href='#!'
                          active={activeItem === '2'}
                          onClick={this.handleUserStatus('2')}>
                          Deactivated
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBCol>
                </>
              ) : (
                <MDBCol md="12" className="text-center">
                  {
                    !showVoided ?
                      (
                        <h2 className="text-success">Active Users</h2>
                      ) : (
                        <h2 className="text-danger">Deactivated Users</h2>
                      )
                  }
                </MDBCol>
              )
            }
          </MDBRow>
          <MDBRow>
            <MDBDataTable className="user-table"
              responsive
              hover
              data={data}
            />
          </MDBRow>
          <MDBRow>
            <MDBModal
              backdrop={false}
              isOpen={modal2}
              toggle={this.toggle(2 + '@' + 0)}
              size="sm">
              <MDBModalHeader toggle={this.toggle(2 + '@' + 0)}>
                Please confirm...
              </MDBModalHeader>
              <MDBModalBody>
                Click "Yes", to <span class="red-text">deactivate</span> this user.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="danger" onClick={this.handleDeleteUser} size="sm">
                  Yes
                </MDBBtn>
                <MDBBtn color="primary" size="sm" onClick={this.toggle(2 + '@' + 0)}>
                  Cancel
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal
              backdrop={false}
              isOpen={modal3}
              toggle={this.toggle(3 + '@' + 0)}
              size="sm">
              <MDBModalHeader toggle={this.toggle(3 + '@' + 0)}>
                Please confirm...
              </MDBModalHeader>
              <MDBModalBody>
                Click "Yes", to <span class="green-text">activate</span> this user.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="success" onClick={this.handleRestoreUser} size="sm">
                  Yes
                </MDBBtn>
                <MDBBtn color="primary" size="sm" onClick={this.toggle(3 + '@' + 0)}>
                  Cancel
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal
              backdrop={false}
              isOpen={modal4}
              toggle={this.toggle(4 + '@' + 0)}
              size="sm">
              <MDBModalHeader toggle={this.toggle(4 + '@' + 0)}>
                Deactivate User
              </MDBModalHeader>
              <MDBModalBody>
                Cannot deactivate Yourself As of Now!!!!
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="warning" size="sm" onClick={this.toggle(4 + '@' + 0)}>
                  Ok
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            {/* Render create-user-form */}
            <InputForm
              modal5={modal5}
              showPrivate={showPrivate}
              showVoided={showVoided}
              toggleModal={this.toggle}
              getAllUsers={this.getAllUsers}          
            />
            <MDBModal 
              backdrop={false} 
              isOpen={modal6} 
              toggle={this.toggle(6 + '@' + 0)} 
              size="sm"
            >
              <MDBModalHeader toggle={this.toggle(6 + '@' + 0)}>
                View User Details
              </MDBModalHeader>
              <MDBModalBody>
                Viewing Another Person's Information Under Implementation!
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="warning" size="sm" onClick={this.toggle(6 + '@' + 0)}>Ok</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal 
              backdrop={false} 
              isOpen={modal7} 
              toggle={this.toggle(7 + '@' + 0)} 
              size="sm"
            >
              <MDBModalHeader toggle={this.toggle(7 + '@' + 0)}>
                View User Details
              </MDBModalHeader>
              <MDBModalBody>
                This User Is Deactivated
                </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="warning" size="sm" onClick={this.toggle(7 + '@' + 0)}>
                  Ok
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </MDBRow>
        </div>
      </>
    )
  }
};

const mapStateToProps = state => ({ user: state })

export default connect(mapStateToProps)(HQTeam);
