import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { format } from 'date-fns'
import CreateUserForm from './createUserForm'
import { createUserSchema } from './components/validate'
import { saveNewUser } from '../../store/dispatchers'

const InputForm = ({
  modal5,
  showPrivate,
  showVoided,
  toggleModal = f => f,
  getAllUsers = f => f,
  dispatch = f => f,
  user = {}
}) => {
  const values = {
    given_name: '',
    surname: '',
    primary_email: '',
    phone1: '',
    gender: '',
    join_date: '',
    position: '',
    department: '',
    user_group: user.role,
    supervisor: ''
  }

  return (
    <Formik
      initialValues={values}
      validationSchema={createUserSchema}
      render={
        (props) =>
          <CreateUserForm
            {...props}
            users={user.user_profile_reducer}
            modal5={modal5}
            toggleModal={toggleModal}
          />
      }
      onSubmit={
        async (values, actions) => {
          const userData = {
            ...values,
            primary_phone_number: values.phone1,
            join_date: format(values.join_date, 'yyyy-MM-dd'),
            manger_id: values.supervisor.value
          }
          await dispatch(saveNewUser(sessionStorage.getItem('token'), userData))
          await getAllUsers(showPrivate, showVoided)
          setTimeout(() => {
            actions.setSubmitting(false)
            actions.resetForm()
          }, 500)
        }
      }
    />
  )
}

InputForm.propTypes = {
  modal5: PropTypes.bool,
  showPrivate: PropTypes.bool,
  showVoided: PropTypes.bool,
  user: PropTypes.object,
  toggleModal: PropTypes.func,
  getAllUserrs: PropTypes.func
}

const mapStateToProps = state => ({ user: state })

export default connect(mapStateToProps)(InputForm)
