import React from 'react'
import { Redirect } from 'react-router-dom'
import apiCall from '../../../apiHelper'
import jwt_decode from 'jwt-decode'
import toastr from 'toastr'
import GoogleLogin from 'react-google-login'
import Cookies from 'universal-cookie'
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdbreact'

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-top-left',
  toastClass: 'toastr'
}

class HQLogin extends React.Component {
  /**
   * The constructer basically binds creates a state object calls super props function and binds the login
   * handleCancelledPopUp function and handle login to this
   * redirectToReferrer is set to false because by defaut we are sent to the login page
   * @param  object         props
   */
  constructor (props) {
    super(props)
    this.state = {
      redirectToReferrer: false,
      email: '',
      loginError: false,
      successMessage: '',
      token: '',
      googleTokenID: '',
      validToken: '',
      hasLoaded: true,
      googleResponse: {}
      // loadClass: false
    }
    this.login = this.login.bind(this)
    this.handleCancelledPopUp = this.handleCancelledPopUp.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount () {
    this.handleBackground()
  };

  handleBackground () {
    const root = document.getElementsByTagName('html')[0]
    root.setAttribute('class', 'sky-theme')
  };

  /**
   * this is a function that handles failed google login attempts such as pop up being closed by user
   * @return string   error   This is the error message returned after a failed login attempt
   */
  handleCancelledPopUp () {
    const error = 'Authentication failed or incomplete.'
    return toastr.error(error)
  }

  /**
   * This funtion calls the kanzuHqAuth's authenticate function which takes in a call back function that
   * sets the state's redirectToReferrer to true hence sending to the page the user was trying to access.
   * @return Boolean  redirectToReferrer this is an object key from the state object that once set to true redirects a page
   */
  login () {
    return kanzuHqAuth.authenticate(() => {
      this.setState({
        redirectToReferrer: true
      })
    })
  }

  /**
   * This is the function that is executed after the google authentication and it looks
   * for the passed username from the the apicall
   * @return string   error object key for the toastr object.
   * @return string   success object key for the toastr object
   */
  handleLogin () {
    this.setState({ hasLoaded: false })
    const loginDetails = { email: this.state.email, googleAuthResponse: this.state.googleTokenID }
    apiCall(loginDetails, '', 'post', 'users/login')
      .then((response) => {
        this.setState({ token: response.token, hasLoaded: true, successMessage: response.message })
        const decoded = jwt_decode(response.token)
        sessionStorage.setItem('user_group', decoded.user_group)
        sessionStorage.setItem('initials', response.initials)
        sessionStorage.setItem('token', response.token)
        sessionStorage.setItem('name', decoded.sub)
        sessionStorage.setItem('user_id', decoded.user_id)
        this.login()
      }).catch(error => {
        this.setState({
          loginError: true,
          hasLoaded: true
        })
        toastr.error(error)
      })
  }

  render () {
    /**
     * This statement redirects a user to the protected page
     * (the page they want to access but only logged users can) they were trying
     *  to access,before they logged ins, after they have logged in.
     *   this statement is only executed when redirectToReferrer to true
    */

    const cookies = new Cookies()
    const { redirectToReferrer, email, successMessage } = this.state
    let name = sessionStorage.getItem('name')
    let token = sessionStorage.getItem('token')
    let validToken = false

    if (token) {
      const decoded = jwt_decode(token)
      validToken = decoded.sub === name
    }
    /**
     * This is a function that returns the email stored in the browser cookie
     * @return string cookieEmail This is the current value of email stored in the browser
     */
    const getCookie = () => {
      const cookieEmail = cookies.get(this.state.email)
      return cookieEmail

    }

    if (redirectToReferrer) {
      const returnEmail = getCookie()
      const pinMessage = 'A pin code has been sent to your email'
      if (returnEmail === email) {
        return (
          toastr.success(successMessage), <Redirect to='/' />
        )
      } else {
        return (
          toastr.success(pinMessage), <Redirect to={{ pathname: '/authenticate', state: { email: this.state.email } }} />
        )
      }
    }

    /**
     * Function that called when the google Authentication is successful and then
     * it calls the handle login function which now confirms whether that user email
     * exists. NOTE googleResponse stores the whole object returned by google
     * @param  object  response      This is the object returned by google containing the details of the user
     * @return object  state         This returns the updated state
     */
    const onSuccessfulLogin = (response) => {
      this.setState({
        email: response.profileObj.email,
        googleTokenID: response.tokenObj.id_token,
        googleResponse: response
      })
      this.handleLogin()
      return this.state
    }

    /**
     * Function that called when the google Authentication is unsuccessful this invokes
     * the handlecancelledPopup function that prompts the user to click the button again to retry
     * @param   object response      This is the object returned by google containing the error message
     * @return  object state         This is the updated state object returned after the function is called
     */
    const onFailedLogin = (response) => {
      this.setState({
        googleResponse: response
      })
      this.handleCancelledPopUp()
      return this.state
    }

    /**
     * this returns a google login component from the react google login package
     * Urgent:remember to add the clientID as a variable from .env file for safety reasons.
     */

    return (
      (token && validToken)
        ? <Redirect to='/' />
        : (
          // redirect to the login
          <MDBContainer fluid className='hq-login sky-theme'>
            <MDBRow>
              <MDBCol sm='12' className='sky-theme'>
                <MDBCard reverse className='sky-theme'>
                  <MDBCardImage
                    cascade
                    className='main-image d-none'
                  />
                  <MDBCardBody cascade className='text-center'>
                    <h1 className='indigo-text main-logo'>
                      <strong>KanzuHQ</strong>
                    </h1>
                    <h3 className='main-message'>Our Online Headquarters</h3>
                    <GoogleLogin
                      className='google-login'
                      clientId='443585092142-lj271nn3lbo7nfngvaoe4laj52kkd4pg.apps.googleusercontent.com'
                      buttonText='Login with Google'
                      theme='dark'
                      onSuccess={onSuccessfulLogin}
                      onFailure={onFailedLogin}
                    />
                    <h6 className='help-text'>
                      <a href='#!'>Having trouble Logging in?</a>
                    </h6>
                    <h6 className='end-text'>
                      KanzuHQ is a product of <a href='https://kanzucode.com' target='_blank' rel='noopener noreferrer'>Kanzu Code</a>
                    </h6>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )
    )
  };
};

export const kanzuHqAuth = {

  isAuthenticated: false,
  authenticate (cb) {
    this.isAuthenticated = true
    // Wait 100 milliseconds to set redirrectTorefferer to true
    setTimeout(cb, 100)
  },

  signout (cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}

export default HQLogin
