import React from 'react'

export const HQPageAvatar = props =>
  <div className='header'>
    <div className='avatar'>
      <img
        src={
          props.prof_pic
            ? props.prof_pic
            : require('../../../../components/common/images/img_placeholder.jpg')
        }
        alt='user-profile'
      />
    </div>
  </div>
