import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Logout, Login, Home, About, Hr, Profile, Auth } from './Layout'
import { Error404 } from './Layout/AppError'
import './stylesheets/mdbreact.scss'
import './stylesheets/kanzustyle/kanzu_style.scss'

const App = () =>
  <Router>
    <div>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/authenticate' component={Auth} />
        <Route path='/logout' component={Logout} />
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/hr' component={Hr} />
        <Route path='/profile' component={Profile} />
        <Route component={Error404} />
      </Switch>
    </div>
  </Router>

export default App
