import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { fetchUser } from '../../store/dispatchers'
import { loadmodaldialog } from '../../store/actions'
import {
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";

class PageTabs extends Component {
  // Stateful component to handle in-page navigation
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "0 || 1",
      nextTab: ''
    }
  };

  togglemodal = (tab, url) => e => {
    if (this.state.activeItem !== tab) {
      if (this.props.isedittable) {
        this.props.dispatch(loadmodaldialog("modal1", url, false));
      }
      else {
        this.setState({
          activeItem: tab
        });
      }
    }
  };

  handleContinue = () => {
    this.props.dispatch(
      fetchUser(sessionStorage.getItem('token'), sessionStorage.getItem('username'))
    );
    this.props.dispatch(
      loadmodaldialog("modal1", this.props.user_modal.nextTab, true)
    );
  };

  render() {
    return (
      <>
        {
          (this.props.isedittable && this.props.user_modal.tabredirect) 
          ? (
              document.location.reload(),
              <Redirect to={this.props.user_modal.nextTab} />
            ) 
          : null
        }
        <MDBRow>
          <MDBCol sm='12' md='11'>
            <MDBNav className="nav-tabs">
              {
                this.props.tab.map(
                  (tab, index) => (
                    <MDBNavItem key={index}>
                      <MDBNavLink 
                        to={
                          (index === 0 && this.props.edit_basic)
                            ? tab.url : (index === 0 && !this.props.edit_basic) 
                              ? tab.url : (index !== 0 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 1 && this.props.edit_emergency) 
                            ? tab.url : (index === 1 && !this.props.edit_emergency) 
                              ? tab.url : (index !== 1 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 2 && this.props.edit_work) 
                            ? tab.url : (index === 2 && !this.props.edit_work) 
                              ? tab.url : (index !== 2 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 3 && this.props.edit_docs) 
                            ? tab.url : (index === 3 && !this.props.edit_docs) 
                              ? tab.url : (index !== 3 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 4 && this.props.edit_asset) 
                            ? tab.url : (index === 4 && !this.props.edit_asset) 
                              ? tab.url : (index !== 4 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 5 && this.props.edit_time_off) 
                            ? tab.url : (index === 5 && !this.props.edit_time_off) 
                              ? tab.url : (index !== 5 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 6 && this.props.edit_courses) 
                            ? tab.url : (index === 6 && !this.props.edit_courses) 
                              ? tab.url : (index !== 6 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 6 && this.props.edit_roles) 
                            ? tab.url : (index === 6 && !this.props.edit_roles) 
                              ? tab.url : (index !== 6 && this.props.isedittable) 
                                ? '#!' : tab.url ||
                          (index === 7 && this.props.edit_personal) 
                            ? tab.url : (index === 7 && !this.props.edit_personal) 
                              ? tab.url : (index !== 7 && this.props.isedittable) 
                                ? '#!' : tab.url
                        }
                    role="tab"
                    // // `match` property is passed down from `HrTabsTemplate`
                    active={this.state.activeItem === tab.home}
                        onClick={this.togglemodal(tab.home, tab.url)}
                      >
                        {tab.label}
                      </MDBNavLink>
                    </MDBNavItem>
                  )
                )
              }
            </MDBNav>
            <MDBModal 
              backdrop={true} 
              isOpen={this.props.user_modal.modal1} 
              toggle={this.togglemodal(1)} 
              size="sm"
            >
              <MDBModalHeader 
                toggle={this.togglemodal(1)}
                className='red-text'
              >
                Warning!</MDBModalHeader>
              <MDBModalBody>
                Your changes will be lost.<br />Please "save" before you continue.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn 
                  color="primary" 
                  size="sm" 
                  onClick={this.togglemodal(1)}
                >
                  Back
                </MDBBtn>
                <MDBBtn 
                  color="danger"
                  size="sm"
                  onClick={this.handleContinue}
                >
                  Continue
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal 
              backdrop={false} 
              isOpen={this.props.user_modal.modal2} 
              size="sm"
            >
              <MDBModalHeader>
                System Updating...
              </MDBModalHeader>
              <MDBModalBody>
                Logging You Out In 6 Seconds,
                Login Again...
            </MDBModalBody>
            </MDBModal>
          </MDBCol>
        </MDBRow>
      </>
    )
  };
  
};

const mapStateToProps = function (state) {
  return {
    user: 
      state.user_profile_reducer,
    user_modal: 
      state.modal_reducer,
    isedittable: 
      state.user_profile_reducer.edit_basic === true || 
      state.user_profile_reducer.edit_emergency === true ||
      state.user_profile_reducer.edit_work === true || 
      state.user_profile_reducer.edit_courses === true ||
      state.user_profile_reducer.edit_personal === true || 
      state.user_profile_reducer.edit_roles === true ||
      state.user_profile_reducer.edit_docs === true || 
      state.user_profile_reducer.edit_asset === true ||
      state.user_profile_reducer.edit_time_off === true,
    edit_basic: 
      state.user_profile_reducer.edit_basic,
    edit_work: 
      state.user_profile_reducer.edit_work,
    edit_emergency: 
      state.user_profile_reducer.edit_emergency,
    edit_courses: 
      state.user_profile_reducer.edit_courses,
    edit_personal: 
      state.user_profile_reducer.edit_personal,
    edit_roles: 
      state.user_profile_reducer.edit_roles,
    edit_docs: 
      state.user_profile_reducer.edit_docs,
    edit_asset: 
      state.user_profile_reducer.edit_asset,
    edit_time_off: 
      state.user_profile_reducer.edit_time_off,
  }
}

export default connect(mapStateToProps)(PageTabs);
