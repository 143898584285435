import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import HQProfileTemplate from './components/hqProfileTemplate'
import TabRoute from './components/tabRoute'
import { Home, Emergency, Job, Asset, Document, Training, BankInfo } from './components/tabPages'
import { TimeOff } from '../hrPage/LeaveMgt'

export const HQProfileHome = () =>
  <HQProfileTemplate>
    <Route exact path='/profile' component={HQProfile} />
    <TabRoute path='/profile/home' component={Home} />
    <TabRoute path='/profile/emergency-contacts' component={Emergency} />
    <TabRoute path='/profile/job' component={Job} />
    <TabRoute path='/profile/documents' component={Document} />
    <TabRoute path='/profile/assets' component={Asset} />
    <TabRoute path='/profile/time-off' component={TimeOff} />
    <TabRoute path='/profile/training' component={Training} />
    <TabRoute path='/profile/bank-info' component={BankInfo} />
  </HQProfileTemplate>

export const HQProfile = (props) => {
  sessionStorage.setItem('username', props.location.state.username)
  return (<Redirect to={{
    pathname: '/profile/home', state: {
      username: props.location.state.username
    }
  }} />)
}
