
export const data = [
  {
    id: 1,
    projectname: 'Kanzu Money',
    description: 'A platform that enables cross-border payments.',
    platform: [
      {
        id: 1,
        name: 'web',
        type: [
          {
            id: 1,
            name: 'Backend',
            stacks: ['node/Express']
          },
          {
            id: 4,
            name: 'Frontend',
            stacks: ['TypeScript', 'React']
          },
          {
            id: 2,
            name: 'Database',
            stacks: ['MySQL']
          },
          {
            id: 3,
            name: 'Others',
            stacks: ['Mojaloop', 'Apache Kafka', 'Kubernates', 'Docker']
          }

        ]
      }
    ]
  },
  {
    id: 2,
    projectname: 'Muzimbi',
    description: 'A platform that builds websites, fast.',
    platform: [
      {
        id: 1,
        name: 'web',
        type: [
          {
            id: 1,
            name: 'Backend',
            stacks: ['php']
          },
          {
            id: 4,
            name: 'Frontend',
            stacks: ['javascript', 'php/Wordpress']
          },
          {
            id: 2,
            name: 'Database',
            stacks: ['MySQL']
          },
          {
            id: 3,
            name: 'Others',
            stacks: ['Woocommerce', "Wordpress Custom Plugin Dev't", "Wordpress Custom Theme Dev't"]
          }

        ]
      }
    ]
  },
  {
    id: 3,
    projectname: 'Kanzu Banking',
    description: 'A product that automates financial operations of SACCOs and Investment Clubs.',
    platform: [
      {
        id: 1,
        name: 'web',
        type: [
          {
            id: 1,
            name: 'Backend',
            stacks: ['java/Spring']
          },
          {
            id: 2,
            name: 'Frontend',
            stacks: ['Angular 1']
          },
          {
            id: 3,
            name: 'Database',
            stacks: ['MySQL']
          }
        ]
      },
      {
        id: 1,
        name: 'Mobile',
        type: [
          {
            id: 1,
            name: 'Backend',
            stacks: ['java/Android']
          },
          {
            id: 2,
            name: 'Others',
            stacks: ['python/django']
          }
        ]
      }
    ]
  },
  {
    id: 4,
    projectname: 'KanzuHQ',
    description: 'The online headquarters of Kanzu Code, which supports the team to work and grow stronger faster.',
    platform: [
      {
        id: 1,
        name: 'web',
        type: [
          {
            id: 1,
            name: 'Backend',
            stacks: ['python/flask']
          },
          {
            id: 2,
            name: 'Frontend',
            stacks: ['javascript/React']
          },
          {
            id: 3,
            name: 'Database',
            stacks: ['PostgreSQL']
          }
        ]
      }
    ]
  }
]
