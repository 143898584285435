import React, { Component } from 'react';
import { MDBCol, MDBRow } from "mdbreact";

class HQProfileDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <MDBRow>
        <MDBCol md='11' className="tab-content">
          Documents Under-implementation
        </MDBCol>
      </MDBRow>
    )
  }
};

export default HQProfileDocument;
