import React from 'react'
import classNames from 'classnames'
import {
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCol,
  MDBCardText,
  MDBProgress
} from 'mdbreact'

const ProgressBar = ({
  tag,
  unit_measure,
  average,
  title,
  target_value,
  update,
  iconBig,
  iconSmall,
  ico
}) => {
  const handleColor = (arg) => {
    const classes = classNames({
      'danger-color': Math.floor(0) <= arg && arg <= Math.floor(25),
      'warning-color-dark': Math.floor(26) <= arg && arg <= Math.floor(50),
      'primary-color': Math.floor(51) <= arg && arg <= Math.floor(75),
      'success-color': Math.floor(76) <= arg && arg <= Math.floor(100)
    })

    return classes
  }

  return (
    <MDBCol sm='6' md='6' xl='6' className='mb-r'>
      <MDBCard className='cascading-admin-card'>
        <div className='admin-up'>
          <MDBIcon icon={iconBig} className={handleColor(average)} />
          <div className='data'>
            <p className='text-uppercase'>{title}</p>
            <h4>
              <strong>{tag === 're' ? unit_measure : ''} {target_value.toLocaleString()} {(tag === 'he' || tag === 're') ? '' : unit_measure}</strong>
            </h4>
          </div>
        </div>
        <MDBCardBody>
          <MDBProgress
            min={0}
            max={100}
            value={average}
            barClassName={handleColor(average)}
          >{average}%
          </MDBProgress>
          <MDBCardText>
            <MDBIcon far={ico} icon={iconSmall} />
            <span className='updater text-muted'>{update}</span>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  )
}

export default ProgressBar
