import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBNavLink,
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBIcon
} from 'mdbreact'

const HrBlockPanel = ({ match }) =>
  <MDBCard className='block hr-card card-body'>
    <MDBRow>
      <MDBBtn>
        <MDBNavLink to={`${match.url}/request-time`}>
          <MDBCol md='12'>
            <MDBCardTitle>request <span>Time-Off</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon icon='umbrella-beach' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
      <MDBBtn>
        <MDBNavLink to='/about/team'>
          <MDBCol md='12'>
            <MDBCardTitle>the <span>Team</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon icon='users' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
      <MDBBtn>
        <MDBNavLink to={`${match.url}/reports`}>
          <MDBCol md='12'>
            <MDBCardTitle>our <span>Reports</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon far icon='file-alt' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
    </MDBRow>
    <MDBRow>
      <MDBBtn>
        <MDBNavLink to={`${match.url}/training`}>
          <MDBCol md='12'>
            <MDBCardTitle>the <span>Training</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon icon='hands-helping' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
      <MDBBtn>
        <MDBNavLink to={`${match.url}/guides`}>
          <MDBCol md='12'>
            <MDBCardTitle>our <span>Guides</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon icon='info' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
      <MDBBtn>
        <MDBNavLink
          to={{ pathname: '/profile', state: { username: sessionStorage.getItem('name') } }}
        >
          <MDBCol md='12'>
            <MDBCardTitle>My <span>Profile</span></MDBCardTitle>
            <MDBCardText className='text-right'>
              <MDBIcon far icon='check-square' size='4x' />
            </MDBCardText>
          </MDBCol>
        </MDBNavLink>
      </MDBBtn>
    </MDBRow>
  </MDBCard>

export default HrBlockPanel
