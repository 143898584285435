import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { MDBInput, MDBCol, MDBRow, MDBIcon, MDBBtn } from "mdbreact";
import { fetchUser, updateUser } from '../../../../store/dispatchers'
import { 
  enableEditUserDetails, 
  handleEditUserDetails, 
  redirectUserToLoginAgain
} from '../../../../store/actions'

class HQProfileBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveUpdate = this.handleSaveUpdate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  subyears(date, year) {
    const now = moment(date)
    return now.subtract(year, 'years')
  }

  handleEdit = event => {
    event.preventDefault();
    this.props.dispatch(enableEditUserDetails(event.target.name))
  }

  handleSaveUpdate = (event) => {
    event.preventDefault();
    let editted = {
      given_name: this.props.user.given_name,
      middle_name: this.props.user.middle_name,
      surname: this.props.user.surname,
      username: this.props.user.username,
      profile_pic_url: this.props.user.pic_url,
      gender: this.props.user.gender,
      birthdate: moment(this.props.user.birthdate).format("YYYY-MM-DD"),
      physical_address: { street: this.props.user.address },
      primary_email: this.props.user.email1,
      secondary_email: this.props.user.email2,
      primary_phone_number: this.props.user.phone1,
      secondary_phone_number: this.props.user.phone2,
      social_media_links: {
        facebook: this.props.user.facebook,
        twitter: this.props.user.twitter,
        slack: this.props.user.slack,
        linkedin: this.props.user.linkedin,
        bitbucket: this.props.user.bitbucket
      }
    }

    this.props.dispatch(
      updateUser(
        editted, 
        this.props.user.id, 
        this.props.user.isuserupdated, 
        'home',
        this.props.user.username, 
      )
    );
  }

  handleCancel = (event) => {
    event.preventDefault();
    this.props.dispatch(
      fetchUser(sessionStorage.getItem('token'), this.props.user.username)
    );
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.dispatch(
      handleEditUserDetails(event.target.name, event.target.value)
    );
  }

  handleDateChange = date => {
    this.props.dispatch(
      handleEditUserDetails("birthdate", date)
    );
  }

  render() {
    return (
      <>
        {
          this.props.user_redirect.redirect 
          ? (
              <Redirect 
                to='/login' 
              />,
              this.props.dispatch(redirectUserToLoginAgain()),
              document.location.reload()
            )
          : null
        }
        <MDBRow>
          <MDBCol md='11' className="tab-content">
            <MDBRow>
              <MDBCol md='10'>
                <h5 className='text-left'>
                  Basic Personal Info
                </h5><hr />
              </MDBCol>
              <MDBCol md='2'>
                <div className="text-right">
                  {!this.props.user.edit_basic &&
                    <MDBBtn 
                      color="indigo" 
                      className="edit-button" 
                      name="edit_basic" 
                      onClick={this.handleEdit}
                    >
                      Edit
                    </MDBBtn>
                  }
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm='12' md='3'>
                <MDBInput 
                  label="First Name" 
                  focus="true" 
                  size="sm" name="given_name" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.given_name 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='3'>
                <MDBInput 
                  label="Middle Name" 
                  size="sm" name="middle_name" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.middle_name 
                      ? this.props.user.middle_name 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='3'>
                <MDBInput 
                  label="Last Name" 
                  size="sm" name="surname" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.surname 
                      ? this.props.user.surname 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='3'>
                <MDBInput 
                  label="User Name" 
                  size="sm" name="username" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.username 
                      ? this.props.user.username 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
            </MDBRow>
            <p>Gender, Physical Address & D.O.B</p>
            <MDBRow>
              <MDBCol sm='12' md='4'>
                <div className="form-group">
                  <input 
                    type="radio" 
                    disabled={this.props.user.readonly} 
                    name="gender" 
                    onChange={this.handleChange} 
                    checked={
                      this.props.user.user[0] 
                      ? this.props.user.gender === "M" 
                        ? true 
                        : false 
                      : false
                    } 
                    value="M" 
                  /> Male<br />
                  <input 
                    type="radio" 
                    disabled={this.props.user.readonly} 
                    name="gender" 
                    onChange={this.handleChange} 
                    checked={
                      this.props.user.user[0] 
                      ? this.props.user.gender === "F" 
                        ? true 
                        : false 
                      : false
                    } 
                    value="F" 
                  /> Female<br />
                </div>
              </MDBCol>
              <MDBCol sm='12' md='5'>
                <MDBInput 
                  label="Physical Address (Area of Residence)" 
                  size="sm" 
                  name="address" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.address 
                      ? this.props.user.address 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='3'>
                <label className="dob">D.O.B (yyyy-MM-dd)</label>
                <DatePicker className="datepick"
                  dateFormat="yyyy-MM-dd"
                  readOnly={this.props.user.readonly}
                  maxDate={new Date(this.subyears(new Date(), 10))}
                  selected={
                    this.props.user.user[0] 
                    ? this.props.user.birthdate 
                      ? this.props.user.birthdate 
                      : '' 
                    : ''
                  }
                  onChange={this.handleDateChange}
                />
              </MDBCol>
            </MDBRow>
            <p>Phone Contacts & Profile Image</p>
            <MDBRow>
              <MDBCol sm='12' md='4'>
                <MDBInput 
                  label="Primary Phone Number" 
                  size="sm" 
                  name="phone1" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.phone1 
                      ? this.props.user.phone1 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='5'>
                <MDBInput 
                  label="Secondary Phone Number" 
                  size="sm" 
                  name="phone2" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.phone2 
                      ? this.props.user.phone2 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='3'>
                <MDBInput 
                  label="Profile Pic Url" 
                  size="sm" 
                  name="pic_url" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.pic_url 
                      ? this.props.user.pic_url 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
            </MDBRow>
            <p>Email Contacts</p>
            <MDBRow>
              <MDBCol sm='12' md='4'>
                <MDBInput 
                  label="Primary Email Address" 
                  size="sm" 
                  name="email1" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.email1 
                      ? this.props.user.email1 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
              <MDBCol sm='12' md='5'>
                <MDBInput 
                  label="Secondary Email Address" 
                  size="sm" 
                  name="email2" 
                  onChange={this.handleChange} 
                  value={
                    this.props.user.user[0] 
                    ? this.props.user.email2 
                      ? this.props.user.email2 
                      : '' 
                    : ''
                  } 
                  readOnly={this.props.user.readonly} 
                />
              </MDBCol>
            </MDBRow>
            <p>Social Links</p>
            {
              this.props.user.user[0] 
              ? !this.props.user.edit_basic && (
                <MDBRow>
                  <MDBCol sm='12' md='12'>
                    <div>
                      {
                        this.props.user.facebook &&
                          <a href={this.props.user.facebook}>
                            <MDBIcon 
                              title='Facebook Handle' 
                              fab icon="facebook" 
                              size="2x" 
                            />
                          </a>
                      }
                      &nbsp;&nbsp;&nbsp;
                      {
                        this.props.user.linkedin &&
                          <a href={this.props.user.linkedin}>
                            <MDBIcon 
                              title='LinkedIn Handle' 
                              fab icon="linkedin" 
                              size="2x" 
                            />
                          </a>
                      }
                      &nbsp;&nbsp;&nbsp;
                      {
                        this.props.user.twitter &&
                          <a href={this.props.user.twitter}>
                            <MDBIcon 
                              title='Twitter Handle' 
                              fab icon="twitter" 
                              size="2x" 
                            />
                          </a>
                      }
                      &nbsp;&nbsp;&nbsp;
                      {
                        this.props.user.slack &&
                          <a href={this.props.user.slack}>
                            <MDBIcon 
                              title='Slack Handle' 
                              fab icon="slack" 
                              size="2x" 
                            />
                          </a>
                      }
                      &nbsp;&nbsp;&nbsp;
                      {
                        this.props.user.bitbucket &&
                          <a href={this.props.user.bitbucket}>
                            <MDBIcon 
                              title='Bitbucket Handle' 
                              fab icon="bitbucket" 
                              size="2x" 
                            />
                          </a>
                      }
                    </div>
                  </MDBCol>
                </MDBRow>
              ) : (
                <div></div>
              )
            }
            {
              this.props.user.edit_basic && (
                <MDBRow>
                  <MDBCol sm='6' md='3'>
                    <MDBInput
                      label="Facebook" 
                      size="sm" 
                      name="facebook" 
                      onChange={this.handleChange} 
                      value={
                        this.props.user.user[0] 
                        ? this.props.user.facebook 
                          ? this.props.user.facebook 
                          : '' 
                        : ''
                      } 
                      readOnly={this.props.user.readonly} 
                    />
                  </MDBCol>
                  <MDBCol sm='6' md='3'>
                    <MDBInput 
                      label="LinkedIn" 
                      size="sm" 
                      name="linkedin" 
                      onChange={this.handleChange} 
                      value={
                        this.props.user.user[0] 
                        ? this.props.user.linkedin 
                          ? this.props.user.linkedin 
                          : '' 
                        : ''
                      } 
                      readOnly={this.props.user.readonly} 
                    />
                  </MDBCol>
                  <MDBCol sm='6' md='3'>
                    <MDBInput 
                      label="Twitter" 
                      size="sm" 
                      name="twitter" 
                      onChange={this.handleChange} 
                      value={
                        this.props.user.user[0] 
                        ? this.props.user.twitter 
                          ? this.props.user.twitter 
                          : '' 
                        : ''
                      } 
                    readOnly={this.props.user.readonly} 
                  />
                  </MDBCol>
                  <MDBCol sm='6' md='3'>
                    <MDBInput 
                      label="Slack" 
                      size="sm" 
                      name="slack" 
                      onChange={this.handleChange} 
                      value={
                        this.props.user.user[0] 
                        ? this.props.user.slack 
                          ? this.props.user.slack 
                          : '' 
                        : ''
                      } 
                      readOnly={this.props.user.readonly} 
                    />
                  </MDBCol>
                </MDBRow>
              )
            }
            {
              this.props.user.edit_basic && (
                <MDBRow>
                  <MDBCol sm='6' md='3'>
                    <MDBInput 
                      label="bitbucket" 
                      size="sm" 
                      name="bitbucket" 
                      onChange={this.handleChange} 
                      value={
                        this.props.user.user[0] 
                        ? this.props.user.bitbucket 
                          ? this.props.user.bitbucket 
                          : '' 
                        : ''
                      } 
                      readOnly={this.props.user.readonly} 
                    />
                  </MDBCol>
                </MDBRow>
              )
            }
            {
              this.props.user.edit_basic && (
                <MDBRow>
                  <MDBCol md="12">
                    <div className="text-right">
                      <MDBBtn 
                        color="primary"
                        className='action-btn'
                        name="save-basic" 
                        onClick={this.handleSaveUpdate}
                      >
                        {this.props.user.hasLoaded &&
                          <>
                            <span
                              className='spinner-border spinner-border-sm'
                              aria-hidden='true'
                              role='status'
                            ></span>
                            <span>&nbsp;</span>
                          </>
                        }
                          Save
                      </MDBBtn>
                      <MDBBtn 
                        color="danger" 
                        className='action-btn'
                        name="cancel-basic" 
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              )
            }
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user_profile_reducer,
  user_redirect: state.modal_reducer
})

export default connect(mapStateToProps)(HQProfileBasic);
