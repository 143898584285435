import React from 'react'
import HQPageTemplate from './components/hqTemplate'
import HQMainPage from './mainPage'

const HQHome = () => (
  <HQPageTemplate>
    <HQMainPage />
  </HQPageTemplate>
);

export default HQHome;
