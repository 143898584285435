import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBInput, MDBCol, MDBRow, MDBBtn } from "mdbreact";
import { fetchUser, updateUser } from '../../../../store/dispatchers'
import { enableEditUserDetails, handleEditUserDetails } from '../../../../store/actions'

class HQProfileEmergency extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveUpdate = this.handleSaveUpdate.bind(this);
  }

  handleSaveUpdate = (event) => {
    event.preventDefault();
    let editted = {
      primary_emergency_contact: {
        name: this.props.user.emergency1_name,
        phone: this.props.user.emergency1_phone,
        email: this.props.user.emergency1_email,
        physical_address: this.props.user.emergency1_residence
      },
      secondary_emergency_contact: {
        name: this.props.user.emergency2_name,
        phone: this.props.user.emergency2_phone,
        email: this.props.user.emergency2_email,
        physical_address: this.props.user.emergency2_residence
      },
    }
    this.props.dispatch(updateUser(editted, this.props.user.id, this.props.user.isuserupdated, 'emergency', this.props.user.username));
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.dispatch(enableEditUserDetails(event.target.name))
  }

  handleCancel = (event) => {
    event.preventDefault();
    this.props.dispatch(fetchUser(sessionStorage.getItem('token'), this.props.user.username));
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.dispatch(handleEditUserDetails(event.target.name, event.target.value))
  }

  render() {
    return (
      <MDBRow>
        <MDBCol md='11' className="tab-content">
          <MDBRow>
            <MDBCol md='10'>
              <h5 className='text-left'>
                Next of Kin
              </h5><hr />
            </MDBCol>
            <MDBCol md='2'>
              <div className='text-right'>
                {!this.props.user.edit_emergency &&
                  <MDBBtn
                    color="indigo"
                    className="edit-button"
                    name="edit_emergency"
                    onClick={this.handleEdit}
                  >
                    Edit
                  </MDBBtn>
                }
              </div>
            </MDBCol>
          </MDBRow>
          <p>Primary Contacts</p>
          <MDBRow>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Name"
                name="emergency1_name"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency1_name
                      ? this.props.user.emergency1_name
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Contact Number"
                name="emergency1_phone"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency1_phone
                      ? this.props.user.emergency1_phone
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Physical Addresss"
                name="emergency1_residence"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency1_residence
                      ? this.props.user.emergency1_residence
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Email Address"
                name="emergency1_email"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency1_email
                      ? this.props.user.emergency1_email
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
          </MDBRow>
          <p>Secondary Contacts</p>
          <MDBRow>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Name"
                name="emergency2_name"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency2_name
                      ? this.props.user.emergency2_name
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput 
                label="Contact Number"
                name="emergency2_phone"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency2_phone
                      ? this.props.user.emergency2_phone
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput
                label="Physical Addresss"
                name="emergency2_residence"
                onChange={this.handleChange} 
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency2_residence
                      ? this.props.user.emergency2_residence
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='3'>
              <MDBInput
                label="Email Address"
                name="emergency2_email"
                onChange={this.handleChange} 
                value={
                  this.props.user.user[0]
                    ? this.props.user.emergency2_email
                      ? this.props.user.emergency2_email
                      : ''
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
          </MDBRow>
          {this.props.user.edit_emergency &&
            <MDBRow>
              <MDBCol md="12">
                <div className="text-right">
                  <MDBBtn
                    color="primary"
                    className='action-btn'
                    name="save-emergency"
                    onClick={this.handleSaveUpdate}
                  >
                    {this.props.user.hasLoaded &&
                      <>
                        <span
                          className='spinner-border spinner-border-sm'
                          aria-hidden='true'
                          role='status'
                        ></span>
                        <span>&nbsp;</span>
                      </>
                    }
                    Save
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className='action-btn'
                    name="cancel-emergency"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          }
        </MDBCol>
      </MDBRow>
    )
  }
}

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HQProfileEmergency);
