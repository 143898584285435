const InitialState = {
  user: [],
  error: null,
  id: '',
  given_name: '',
  middle_name: '',
  surname: '',
  birthdate: '',
  address: '',
  phone1: '',
  phone2: '',
  username: '',
  pic_url: '',
  email1: '',
  email2: '',
  emergency1_name: '',
  emergency1_phone: '',
  emergency1_email: '',
  emergency1_residence: '',
  emergency2_name: '',
  emergency2_phone: '',
  emergency2_email: '',
  emergency2_residence: '',
  degree: '',
  facebook: '',
  linkedin: '',
  twitter: '',
  slack: '',
  bitbucket: '',
  position: '',
  department: '',
  date_joined: '',
  manager_username: '',
  manager_given_name: '',
  manager_surname: '',
  manager_id: '',
  gender: 'undefined',
  nin: '',
  tin: '',
  salary: '',
  currency: '',
  nssf: '',
  courses: '',
  bank_name: '',
  bank_branch: '',
  acc_number: '',
  acc_name: '',
  role: 'user',
  isCurrentUser: true,
  edit_basic: false,
  edit_work: false,
  edit_emergency: false,
  edit_courses: false,
  edit_personal: false,
  edit_roles: false,
  edit_docs: false,
  edit_asset: false,
  edit_time_off: false,
  isAdmin: false,
  hasLoaded: false,
  isVoided: false,
  createdBy: '',
  createdDate: '',
  voidedBy: '',
  voidedDate: '',
  updatedBy: '',
  updatedDate: '',
  reports: [{ names: { username: '', given_name: '', surname: '' } }],
  readonly: true,
  events: [],
  growth_plan: [],
  team: [],
  isuserupdated: false,
  modal1: false,
  modal2: false,
  redirect: false,
  nextTab: '',
  tabredirect: false,
  metrics: [],
  updateMetricModal: false,
  new_metric_update: {},
  submetric_update: {},
  timeOff: {},
  userLeaveRequests: {},
  checkLeaveDays: {},
  leave_requests: [],
  user_notifications: [],
  users_out: []
}

export default InitialState
