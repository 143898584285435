import React from 'react'
import DetailedCardView from './detailedCardView'

const MetriCards = (props) =>
  <div className='detailed-cards'>
    {props.submetrics.map(
      (prop, i) =>
        <DetailedCardView
          key={i}
          {...prop}
          handleLoadUpdateDialog={props.onhandleLoadUpdateDialog}
        />
    )}
  </div>

export default MetriCards
