import React from 'react'
import { Route } from 'react-router-dom'
import PageTabs from '../../components/pageTabs'

const HrTabsTemplate = ({ match }) => {
  const hrTabs = [
    {
      label: 'Home',
      home: '1',
      url: '/hr#'
    },
    {
      label: 'Reports',
      home: '2',
      url: '/hr/reports'
    },
    {
      label: 'Training',
      home: '3',
      url: '/hr/training'
    },
    {
      label: 'Time-Off',
      home: '4',
      url: '/hr/request-time'
    },
    {
      label: 'Assets',
      home: '5',
      url: '#!'
    },
    {
      label: 'Guides',
      home: '6',
      url: '/hr/guides'
    },
    {
      label: 'More',
      home: '7',
      url: '#!'
    }
  ]

  return <Route component={() => <PageTabs tab={hrTabs} />} />
}

export default HrTabsTemplate
