import C from './constants'
import InitialState from './data/initialState'

// handles user state data
export function userReducer (state = InitialState, action) {
  switch (action.type) {
    case C.FETCH_USER_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors and start afresh.
      return {
        ...state,
        hasLoaded: !state.hasLoaded,
        error: null
      }

    case C.FETCH_USER_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        hasLoaded: false,
        user: action.payload.param,
        id: action.payload.param[0] ? action.payload.param[0].id : '',
        given_name: action.payload.param[0] ? action.payload.param[0].names.given_name : '',
        middle_name: action.payload.param[0] ? action.payload.param[0].names.middle_name ? action.payload.param[0].names.middle_name : '' : '',
        surname: action.payload.param[0] ? action.payload.param[0].names.surname : '',
        username: action.payload.param[0] ? action.payload.param[0].names.username : '',
        birthdate: action.payload.param[0] ? new Date(action.payload.param[0].birthdate) : '',
        address: action.payload.param[0] ? action.payload.param[0].physical_address.street : '',
        phone1: action.payload.param[0] ? action.payload.param[0].phone_numbers.primary_phone_number : '',
        phone2: action.payload.param[0] ? action.payload.param[0].phone_numbers.secondary_phone_number : '',
        email1: action.payload.param[0] ? action.payload.param[0].emails.primary_email : '',
        email2: action.payload.param[0] ? action.payload.param[0].emails.secondary_email : '',
        emergency1_name: action.payload.param[0] ? action.payload.param[0].emergency_contacts.primary_emergency_contact.name : '',
        emergency1_phone: action.payload.param[0] ? action.payload.param[0].emergency_contacts.primary_emergency_contact.phone : '',
        emergency1_email: action.payload.param[0] ? action.payload.param[0].emergency_contacts.primary_emergency_contact.email : '',
        emergency1_residence: action.payload.param[0] ? action.payload.param[0].emergency_contacts.primary_emergency_contact.physical_address : '',

        emergency2_name: action.payload.param[0] ? action.payload.param[0].emergency_contacts.secondary_emergency_contact.name : '',
        emergency2_phone: action.payload.param[0] ? action.payload.param[0].emergency_contacts.secondary_emergency_contact.phone : '',
        emergency2_email: action.payload.param[0] ? action.payload.param[0].emergency_contacts.secondary_emergency_contact.email : '',
        emergency2_residence: action.payload.param[0] ? action.payload.param[0].emergency_contacts.secondary_emergency_contact.physical_address : '',

        degree: action.payload.param[0] ? action.payload.param[0].education.bachelors : '',
        facebook: action.payload.param[0] ? action.payload.param[0].social_media_links.facebook : '',
        linkedin: action.payload.param[0] ? action.payload.param[0].social_media_links.linkedin : '',
        twitter: action.payload.param[0] ? action.payload.param[0].social_media_links.twitter : '',
        slack: action.payload.param[0] ? action.payload.param[0].social_media_links.slack : '',
        bitbucket: action.payload.param[0] ? action.payload.param[0].social_media_links.bitbucket : '',

        position: action.payload.param[0] ? action.payload.param[0].position : '',
        department: action.payload.param[0] ? action.payload.param[0].department : '',
        date_joined: action.payload.param[0] ? new Date(action.payload.param[0].join_date) : '',

        manager_username: action.payload.param[0] ? action.payload.param[0].manager_names ? action.payload.param[0].manager_names.username : '' : '',
        manager_given_name: action.payload.param[0] ? action.payload.param[0].manager_names ? action.payload.param[0].manager_names.given_name : '' : '',
        manager_id: action.payload.param[0] ? action.payload.param[0].manager_id : '',
        manager_surname: action.payload.param[0] ? action.payload.param[0].manager_names ? action.payload.param[0].manager_names.surname : '' : '',
        gender: action.payload.param[0] ? action.payload.param[0].gender : '',

        nin: action.payload.param[0] ? action.payload.param[0].nin : '',
        tin: action.payload.param[0] ? action.payload.param[0].tin : '',
        nssf: action.payload.param[0] ? action.payload.param[0].nssf_number : '',

        salary: action.payload.param[0] ? action.payload.param[0].salary.gross_pay : '',
        currency: action.payload.param[0] ? action.payload.param[0].salary.gross_pay ? 'Gross Salary (UGX)' : 'Gross Salary' : '',
        courses: action.payload.param[0] ? action.payload.param[0].completed_courses.course1 : '',
        isCurrentUser: action.payload.param[0] ? action.payload.param[0].names.username === sessionStorage.getItem('name') : false,

        bank_name: action.payload.param[0] ? action.payload.param[0].bank_account.bank_name : '',
        bank_branch: action.payload.param[0] ? action.payload.param[0].bank_account.bank_branch : '',
        acc_name: action.payload.param[0] ? action.payload.param[0].bank_account.acc_name : '',
        acc_number: action.payload.param[0] ? action.payload.param[0].bank_account.acc_number : '',
        role: action.payload.param[0] ? action.payload.param[0].user_group : 'user',
        isAdmin: sessionStorage.getItem('user_group') === 'admin',
        isVoided: action.payload.param[0] ? action.payload.param[0].is_voided : false,

        createdBy: action.payload.param[0] ? action.payload.param[0].created_by : '',
        createdDate: action.payload.param[0] ? action.payload.param[0].created_date : '',
        voidedBy: action.payload.param[0] ? action.payload.param[0].voided_by : '',
        voidedDate: action.payload.param[0] ? action.payload.param[0].voided_date : '',

        updatedBy: action.payload.param[0] ? action.payload.param[0].updated_by : '',
        updatedDate: action.payload.param[0] ? action.payload.param[0].updated_date : '',

        events: action.payload.param[0] ? action.payload.param[0].events : [],
        growth_plan: action.payload.param[0] ? action.payload.param[0].growth_plan : [],
        pic_url: action.payload.param[0] ? action.payload.param[0].profile_pic_url : '',
        edit_basic: false,
        edit_work: false,
        edit_emergency: false,
        edit_courses: false,
        edit_personal: false,
        edit_roles: false,
        edit_docs: false,
        edit_asset: false,
        edit_time_off: false,
        readonly: true,
        isuserupdated: false
      }

    case C.FETCH_USER_FAILURE:
      return {
        ...state,
        hasLoaded: false,
        error: action.payload.error,
        user: []
      }

    case C.EDIT_USER_DETAILS:
      return {
        ...state,
        [action.payload.param1]: action.payload.param2,
        isuserupdated: true
      }

    case C.ENABLE_EDIT_USER_DETAILS:
      return {
        ...state,
        [action.payload.param]: true,
        tabredirect: false,
        readonly: false
      }

    case C.FETCH_ALL_USERS:
      return {
        ...state,
        hasLoaded: false,
        team: action.payload.param
      }

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
};

// handles modal dialogs states
export function modalReducer (state = InitialState, action) {
  switch (action.type) {
    case C.LOAD_TAB_MODAL_DIALOG:
      return {
        ...state,
        [action.payload.param]: !state.modal1,
        nextTab: action.payload.param1,
        tabredirect: action.payload.param2
      }

    case C.LOAD_UPDATE_USERNAME_MODAL_DIALOG:
      return {
        ...state,
        modal2: !state.modal2
      }

    case C.REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirect: !state.redirect
      }

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
};

// handles metrics state data
export function metricsReducer (state = InitialState, action) {
  switch (action.type) {
    case C.EDIT_KEY_METRICS:
      return {
        ...state,
        new_metric_update: {
          ...state.new_metric_update,
          [action.payload.param1]: action.payload.param2
        },
        submetric_update: {
          ...state.submetric_update,
          [action.payload.param1]: action.payload.param2
        }
      }

    case C.SET_KEY_METRICS_VALUE:
      return {
        ...state,
        [action.payload.param1]: action.payload.param2
      }

    case C.FETCH_METRICS:
      return {
        ...state,
        [action.payload.param]: action.payload.param1
      }

    default:
      // ALWAYS have a default case in a reducer
      return state
  }
};

// handles leave state data
export function leaveReducer (state = InitialState, action) {
  switch (action.type) {
    case C.FETCH_LEAVE_TYPES:
      return {
        ...state,
        [action.payload.param]: action.payload.param1
      }

    case C.FETCH_LEAVE_REQUESTS:
      return {
        ...state,
        [action.payload.param]: action.payload.param1
      }

    case C.FETCH_USER_LEAVE_REQUESTS:
      return {
        ...state,
        [action.payload.param]: action.payload.param1
      }

    case C.LOAD_LEAVE_DAYS_BALANCE:
      return {
        ...state,
        [action.payload.param]: action.payload.param1
      }

    default:
      return state
  }
}
