import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { fetchUser, updateUser } from '../../../../store/dispatchers'
import { enableEditUserDetails, handleEditUserDetails } from '../../../../store/actions'

class HQProfileJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      team: [],
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveUpdate = this.handleSaveUpdate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount = () => {
    let users = []
    this.props.user.team.map((user, index) => {
      let user1 = {
        value: user.id,
        label: user.names.given_name + ' ' + (user.names.middle_name ? user.names.middle_name : '') + ' ' + user.names.surname
      }
      users.push(user1);
      return user1;
    })
    this.setState({
      team: users
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let users = []
    nextProps.user.team.map((user, index) => {
      let user1 = {
        value: user.id,
        label: user.names.given_name + ' ' + (user.names.middle_name ? user.names.middle_name : '') + ' ' + user.names.surname
      }
      users.push(user1);
      return user1;
    });

    return { team: users }
  }

  handleSaveUpdate = (event) => {
    event.preventDefault();
    let editted = {
      position: this.props.user.position,
      department: this.props.user.department,
      manger_id: this.props.user.manager_id,
      join_date: moment(this.props.user.date_joined).format("YYYY-MM-DD")
    }

    this.props.dispatch(
      updateUser(
        editted, 
        this.props.user.id, 
        this.props.user.isuserupdated, 
        'job', 
        this.props.user.username
      )
    );
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.dispatch(enableEditUserDetails(event.target.name))
  }

  handleCancel = (event) => {
    event.preventDefault();
    this.props.dispatch(fetchUser(sessionStorage.getItem('token'), this.props.user.username));
    this.setState({
      selectedOption: null
    })
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.dispatch(handleEditUserDetails(event.target.name, event.target.value))
  }

  handleDateChange = date => {
    this.props.dispatch(handleEditUserDetails("date_joined", date));
  }

  handleChangeManager = selectedOption => {
    this.setState({ selectedOption });
    this.props.dispatch(handleEditUserDetails("manager_id", selectedOption.value));
  };

  render() {

    const { user } = this.props

    return (
      <MDBRow>
        <MDBCol md='11' className="tab-content">
          <MDBRow>
            <MDBCol md='10'>
              <h5 className='text-left'>
                Work Info
              </h5><hr />
            </MDBCol>
            <MDBCol md='2'>
              {user.isAdmin &&
                <div className="text-right">
                  {!user.edit_work &&
                    <MDBBtn
                      color="indigo"
                      className="edit-button"
                      name="edit_work"
                      onClick={this.handleEdit}
                    >
                      Edit
                    </MDBBtn>
                  }
                </div>
              }
            </MDBCol>
          </MDBRow>
          <p>Position & Department</p>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label="Position"
                name="position"
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.position
                      ? user.position
                      : ''
                    : ''}
                size="sm"
                readOnly={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label="Department"
                name="department"
                onChange={this.handleChange}
                value={
                  user.user[0]
                    ? user.department
                      ? user.department
                      : ''
                    : ''}
                size="sm"
                readOnly={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
          </MDBRow>
          <p>Join Date & Supervisor</p>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <span className="dob">Join Date</span>
              <DatePicker
                className="datepick2"
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                selected={
                  user.user[0]
                    ? user.date_joined
                      ? user.date_joined
                      : ''
                    : ''}
                onChange={this.handleDateChange}
                readOnly={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <span className="dob">Supervisor</span>
              <Select
                className="manager-select"
                placeholder={
                  user.manager_given_name + ' ' + user.manager_surname
                }
                value={this.state.selectedOption}
                onChange={this.handleChangeManager}
                options={this.state.team}
                isDisabled={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label="Direct Reports"
                name="directreports" 
                onChange={this.handleChange}
                size="sm"
                readOnly={user.isAdmin ? user.readonly : true}
              />
            </MDBCol>
          </MDBRow>
          {user.edit_work &&
            <MDBRow>
              <MDBCol md="12">
                <div className="text-right">
                  <MDBBtn 
                    color="primary"
                    className='action-btn'
                    name="save-work"
                    onClick={this.handleSaveUpdate}
                  >
                    {user.hasLoaded &&
                      <>
                        <span
                          className='spinner-border spinner-border-sm'
                          aria-hidden='true'
                          role='status'
                        ></span>
                        <span>&nbsp;</span>
                      </>  
                    }
                    Save
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className='action-btn'
                    name="cancel-work"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          }
        </MDBCol>
      </MDBRow>
    )
  }
};

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HQProfileJob);
