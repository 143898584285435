import apiCall from '../../apiHelper'
import toastr from 'toastr'
import moment from 'moment'
import {
  fetchUserBegin,
  fetchUserSuccess,
  fetchUserFailure,
  fetchAllUsers,
  updateUserUsernameModal,
  redirectUserToLoginAgain,
  loadMetrics,
  loadLeaveTypes,
  loadLeaveRequests,
  loadUserLeaveRequests,
  loadLeaveDays
} from './actions'

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  toastClass: 'toastr'
}

// USER module

// handle fetching user's profile.
export function fetchUser(token, username) {
  return dispatch => {
    return apiCall('', token, 'get', 'users/?showPrivate=true&search=' + username)
      .then((response) => {
        if (!response.error) {
          dispatch(fetchUserSuccess(response.users))
          dispatch(allSystemUsers(token))
          return response.users
        } else {
          dispatch(fetchUserFailure(response.error))
        }
      })
      .catch(error => {
        dispatch(fetchUserFailure(error))
      })
  }
}

// save new system user
export function saveNewUser(token, data) {
  return dispatch => {
    dispatch(fetchUserBegin())
    return apiCall(data, token, 'post', 'users/')
      .then((response) => {
        dispatch(fetchUserBegin())
        if (!response.error) {
          toastr.success(response.message)
        } else {
          toastr.error(response.error.message)
        }
      })
  }
}

// fetch all team members/ all system users.
export function allSystemUsers(token) {
  return dispatch => {
    return apiCall('', token, 'get', 'users/')
      .then((response) => {
        if (response.error) {
          toastr.error(response.error.message)
        } else {
          dispatch(fetchAllUsers(response.users))
          return response.users
        }
      })
      .catch(error => error)
  }
}

// handle updating user profile data.
export function updateUser(data, id, isuserupdated, type, username) {
  return dispatch => {
    dispatch(fetchUserBegin())
    if (isuserupdated) {
      return apiCall(data, sessionStorage.getItem('token'), 'patch', 'users/' + id)
        .then((response) => {
          dispatch(fetchUserBegin())
          if (!response.error) {
            toastr.success(response.message)
            if ((sessionStorage.getItem('username') !== data.username && sessionStorage.getItem('username') === sessionStorage.getItem('name')) && type === 'home') {
              dispatch(updateUserUsernameModal())
              setTimeout(function () {
                sessionStorage.removeItem('username')
                sessionStorage.removeItem('name')
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('user_group')

                dispatch(redirectUserToLoginAgain())
              }, 6000)
            } else {
              dispatch(fetchUser(sessionStorage.getItem('token'), username))
            }
            return response.user
          } else {
            toastr.error(response.error)
          }
        })
        .catch(error => toastr.error(error))
    } else {
      toastr.success('No changes!')
      dispatch(fetchUser(sessionStorage.getItem('token'), username))
      return true
    }
  }
}

// METRICS Module

// loads all metrics
export function fetchMetrics(token) {
  return dispatch => {
    return apiCall('', token, 'get', 'metrics/averages')
      .then((response) => {
        dispatch(loadMetrics('metrics', response))
        return response
      })
  }
}

// save metric updates
export function saveUpdatedMetric(data, submetricid, active) {
  return dispatch => {
    dispatch(fetchUserBegin())
    if (Object.keys(data).length > 0) {
      if (active === '6') {
        return apiCall(data, sessionStorage.getItem('token'), 'patch', 'submetric/' + submetricid)
          .then((response) => {
            dispatch(fetchMetrics(sessionStorage.getItem('token')))
            toastr.success('Updated Successfully.')
          }).catch(() => toastr.error('Something went wrong. Please try again.'))
      } else {
        data['sub_metric_id'] = submetricid
        return apiCall(data, sessionStorage.getItem('token'), 'post', 'metricvalue')
          .then((response) => {
            toastr.success('Update was successful.')
            dispatch(fetchMetrics(sessionStorage.getItem('token')))
          }).catch(() => toastr.error('Something went wrong. Please try again.'))
      }
    } else {
      return toastr.success('No changes!')
    }
  }
}

// LEAVE module

// fetch all leave types available in the system
export function fetchLeaveTypes(token, None = '') {
  return dispatch => {
    if (None === '') {
      return apiCall('', token, 'get', 'leavetypes/' + None)
        .then((response) => {
          dispatch(loadLeaveTypes('timeOff', response))
          return response
        })
        .catch(error => error)
    } else { return null }
  }
}

// fetch all leave requests of a user
export function fetchUserLeaveRequests(token, userId) {
  return dispatch => {
    return apiCall('', token, 'get', 'leaverequests/' + userId)
      .then((response) => {
        dispatch(loadUserLeaveRequests('userLeaveRequests', response))
        return response
      })
      .catch(error => error)
  }
}

// fetch a user's remaining days for a given leave option
export function fetchLeaveDaysBalance(userId, leavetypeId) {
  return dispatch => {
    if (userId !== isNaN && leavetypeId !== null) {
      return apiCall('', sessionStorage.getItem('token'), 'get', 'leavetypes/' + userId + '/' + leavetypeId)
        .then((response) => {
          dispatch(loadLeaveDays('checkLeaveDays', response))
          if (response.balance === 0) {
            return toastr.error('Sorry, no leave days available for that option.')
          }
        })
        .catch(error => console.log(error))
    } else { return null }
  }
}

// create a leave request
export function leaveRequest(token, data) {
  return dispatch => {
    dispatch(fetchUserBegin())
    return apiCall(data, token, 'post', 'leaverequests/')
      .then((response) => {
        dispatch(fetchUserBegin())
        if (!response.error) {
          toastr.success(response.message)
        } else {
          toastr.error(response.error.message)
        }
      })
      .catch(error => error)
  }
}

// fetch all leave requests.
export function allLeaveRequests() {
  return dispatch => {
    return apiCall('', sessionStorage.getItem('token'), 'get', 'leaverequests/?hasresponse=false')
      .then((response) => {
        if (response.leaverequests) {
          let leaves = []
          if (sessionStorage.getItem('user_group') === 'admin') {
            leaves = response.leaverequests
            dispatch(loadLeaveRequests('leave_requests', response.leaverequests))
          } else {
            response.leaverequests.map((value, index) => {
              if (value.manager && sessionStorage.getItem('name') === value.manager.username) {
                leaves.push(value)
              }
              return true
            })
            dispatch(loadLeaveRequests('leave_requests', leaves))
          }
          if (leaves.length > 0) {
            const data = {
              startdate: moment(leaves[0].requested_start_date).format('YYYY-MM-DD'),
              enddate: moment(leaves[0].requested_end_date).format('YYYY-MM-DD')
            }
            apiCall(data, sessionStorage.getItem('token'), 'post', 'leaverequests/out')
              .then((response2) => {
                dispatch(loadLeaveRequests('users_out', response2.users))
              }).catch(error => error)
          }
        } else {
          dispatch(loadLeaveRequests('leave_requests', []))
        }
      })
      .catch(error => error)
  }
}

export function approveLeaveRequest(data) {
  return dispatch => {
    dispatch(fetchUserBegin())
    return apiCall(data, sessionStorage.getItem('token'), 'post', 'leaverequests/response')
      .then((response) => {
        dispatch(allLeaveRequests())
        if (response.message === 'Response to leave request sent successfully') {
          if (data.is_granted === true) {
            toastr.success('You have approved the request')
          } else {
            toastr.success('Leave, denied')
          }
        } else {
          toastr.error(response.message)
        }
      }).catch(() => toastr.error('Something went wrong, try again.'))
  }
}

// list all user notifications
export function allUserNotifications() {
  return dispatch => {
    return apiCall('', sessionStorage.getItem('token'), 'get', 'users/notifications/' + sessionStorage.getItem('user_id'))
      .then((response) => {
        dispatch(loadLeaveRequests('user_notifications', response.user_notifications))
      })
      .catch(error => error)
  }
}

// remove view notification
export function removeUserNotification(id) {
  return dispatch => {
    dispatch(fetchUserBegin())
    return apiCall('', sessionStorage.getItem('token'), 'delete', 'users/notifications/' + id)
      .then((response) => {
        dispatch(allUserNotifications())
      })
      .catch(error => error)
  }
}
