import React from 'react';
import { MDBTable, MDBJumbotron, MDBTableBody, MDBTableHead, MDBRow, MDBCol } from 'mdbreact';
import { data } from './initialState'
import ProjectStacks from './projectStacks'
const Stacks = () => {
    return (
        <MDBRow>
            <MDBCol>
                <h4 className="text-success"><b>Engineering Stacks</b></h4>
                <MDBJumbotron>
                    <section className="stacks-section">
                        <MDBRow>
                            <MDBCol>
                                <MDBTable bordered responsive className="stacks-table">
                                    <MDBTableHead>
                                        <tr>
                                            <th><b>Project Name</b></th>
                                            <th><b>Platform</b></th>
                                            <th className="text-center"><b>Stacks</b></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {data.map((value, i) => {
                                            return value.platform.map((plat, index) =>
                                                index === 0 ? (
                                                    <tr key={index + 'tr'}>
                                                        <td rowSpan={value.platform.length} key={i + 'proj'} className="proj-col">
                                                            <div className={value.platform.length > 1 ? 'proj-name' : ''}>
                                                                <h4> {i + 1}. <b>{value.projectname}</b></h4>
                                                                {value.description}
                                                            </div>
                                                        </td>
                                                        <td key={index} rowSpan="1" className="stack-col">
                                                            {plat.name}
                                                        </td>
                                                        <td><ProjectStacks stacks={plat.type} /></td>
                                                    </tr>
                                                ) : (
                                                        <tr key={index + 'tr'}>
                                                            <td key={index} rowSpan="1" className="stack-col">
                                                                {plat.name}
                                                            </td>
                                                            <td><ProjectStacks stacks={plat.type} /></td>
                                                        </tr>
                                                    )
                                            )
                                        })}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                    </section>
                </MDBJumbotron>
            </MDBCol>
        </MDBRow>


    );
}

export default Stacks;