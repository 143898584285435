import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardText
} from 'mdbreact'

const HrSidePanel = () =>
  <Router>
    <MDBCard className='hr-card card-body'>
      <MDBRow>
        <MDBCol>
          <MDBCardTitle>Employee Details</MDBCardTitle>
          <MDBCardText>
            Some quick example text to build on the panel title and make up the
            bulk of the panel's content.
          </MDBCardText>
          <div className='flex-row'>
            <a href='#!'>Read more</a>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCardTitle>Company</MDBCardTitle>
          <MDBCardText>
            Some quick example text to build on the panel title and make up the
            bulk of the panel's content.
          </MDBCardText>
          <div className='flex-row'>
            <a href='#!'>Read more</a>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCardTitle>Resources</MDBCardTitle>
          <MDBCardText>
            Some quick example text to build on the panel title and make up the
            bulk of the panel's content.
          </MDBCardText>
          <div className='flex-row'>
            <a href='#!'>Read more</a>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  </Router>

export default HrSidePanel
