import React from 'react'
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter
} from 'mdbreact'

const HQFooter = () =>
  <MDBFooter color='deep-purple' className='font-small'>
    <MDBContainer fluid className='text-center text-md-left'>
      <MDBRow>
        <MDBCol sm='12' md='1'></MDBCol>
        <MDBCol sm='12' md='5'>
          <h5 className='title'>KanzuHQ</h5>
          <p>
            Find all the latest information here about our mission,
            vision, values, our culture, our onboarding process,
            links to our stackoverflow, salary information,
            leave application procedure, weekly meeting minutes and
            handbooks as shared from different departments.
            </p>
        </MDBCol>
        <MDBCol sm='12' md='5'>
          <h5 className='title'>Useful links</h5>
          <ul>
            <li className='list-unstyled'>
              <a href='#!'>Company data</a>
            </li>
            <li className='list-unstyled'>
              <a href='#!'>Frequently Asked Questions</a>
            </li>
            <li className='list-unstyled'>
              <a href='#!'>Resources</a>
            </li>
            <li className='list-unstyled'>
              <a href='#!'>Support forum</a>
            </li>
          </ul>
        </MDBCol>
        <MDBCol sm='12' md='1'></MDBCol>
      </MDBRow>
    </MDBContainer>
    <div className='footer-copyright text-center'>
      <MDBContainer fluid>
        &copy; {new Date().getFullYear()} Copyright: <a href='https://kanzucode.com/'> Kanzu Code </a>
      </MDBContainer>
    </div>
  </MDBFooter>

export default HQFooter;
