import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';


const ProjectStacks = (props) => {
    return (
        <MDBRow>
            {props.stacks.map((stack, index) =>
            <MDBCol md='3' key={index}>
                <h6><b>{stack.name}</b></h6>
                {stack.stacks.map((item, i)=>
                 <li key={'li'+i}>{item}</li>
                )}
            </MDBCol>
            )}
        </MDBRow>)
}
export default ProjectStacks;