import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MDBContainer, MDBCol } from 'mdbreact';
import AboutPageTemplate from './components/aboutTemplate';
import { Error404 } from '../../AppError';
import HQTeam from './Team/team';
import Mtracker from '../../AppMetrics'
import Stacks from '../AboutPage/Stacks'

export const HQAbout = () => (
  // Page for "About Kanzu"
  <AboutPageTemplate>
    <Switch>
      <Route
        exact
        path='/about'
        render={() => (
          // Define a component to render here,
          // then remove this inline rendering
          <MDBContainer>
            <MDBCol className='py-5'>
              <h1>[ABOUT KANZU]</h1>
              <br /><br /><br /><br /><br /><br /><br /><br />
            </MDBCol>
          </MDBContainer>
        )}
      />
      <Route
        exact
        path='/about/company'
        render={() => (
          // Define a component to render here,
          // then remove this inline rendering
          <MDBContainer>
            <MDBCol className='py-5'>
              <h1>[Kanzu Code Story]</h1>
              <h2>[How we began?]</h2>
              <br /><br /><br /><br /><br /><br /><br /><br />
            </MDBCol>
          </MDBContainer>
        )}
      />
      <Route path='/about/team' component={HQTeam} />
      <Route path='/about/metrics' component={Mtracker} />
      <Route path='/about/stacks' component={Stacks} /> 
      <Route component={Error404} />
    </Switch>
  </AboutPageTemplate>
);

export default HQAbout;
