import * as request from 'superagent';

export default function apiCall (data, header, type, url) {
  // const contextPath = location.href.split('/')[3];
  // const BASE_URL = `http://0.0.0.0:5000/api/v1/${url}`;
  const BASE_URL = `https://hqapi.kanzucode.com/api/v1/${url}`;
  return new Promise((resolve, reject) => {
    request[type](BASE_URL)
      .send(data)
      .set('Content-Type', 'application/json')
      .set('token', header)
      .end((err, res) => {
        if (res) {
          return resolve(res.body);
        }
        return reject(err);
      });
  });
}
