import React from 'react'
import classNames from 'classnames'
import { MDBRow } from 'mdbreact'

const InputFeedback = ({ error }) =>
  error ? <span className={classNames('invalid-feedback')}>{error}</span> : null

export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {

  const classes = classNames(
    'form-group',
    'selector',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched
    },
    className
  )

  return (
    <div className={classes}>
      <fieldset>
        <legend>{label}</legend>
        <MDBRow>
          {children}
        </MDBRow>
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </div>
  )
}
