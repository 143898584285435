import React from 'react'
import PropTypes from 'prop-types'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import HQPageTemplate from '../../hqPage/components/hqTemplate'
import HrSidePanel from './sidePanel'

const HrPageTemplate = ({ children }) =>
  <HQPageTemplate>
    <MDBRow>
      <MDBContainer fluid className='inner px-0 light-theme'>
        <section className='hq-section hr-page'>
          <MDBRow className='main'>
            <MDBCol md='1' />
            <MDBCol sm='4' md='3' className='hq-content-1 hr-page'>
              <MDBRow className='mb-3'>
                <MDBCol sm='12' md='10'>
                  <HrSidePanel />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol sm='7' md='7' className='hq-content-2 hr-page'>
              {children}
            </MDBCol>
            <MDBCol md='1' />
          </MDBRow>
        </section>
      </MDBContainer>
    </MDBRow>
  </HQPageTemplate>

HrPageTemplate.propTypes = {
  children: PropTypes.any
}

export default HrPageTemplate
