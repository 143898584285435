import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar'
import { HQPageAvatar } from '../../../../hqPage/components/hqAvatar'
import { allLeaveRequests, approveLeaveRequest } from '../../../../../store/dispatchers'
import {
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact'

const HrtabLeavePage = ({ ...props }) => {
  // Place content for leave application here
  useEffect(() => {
    props.dispatch(
      allLeaveRequests()
    )
    // eslint-disable-next-line
  }, [])

  const reasonInput = React.createRef()

  const [modal, setModal] = useState({
    isapproved: false, isdenied: false, reason: ''
  })

  const toggleApproveModal = () => e => {
    e.preventDefault()
    setModal({
      ...modal,
      isapproved: !modal.isapproved
    })
  }

  const toggleDenyModal = () => e => {
    e.preventDefault()
    setModal({
      ...modal,
      isdenied: !modal.isdenied
    })
  }

  const handleApprove = (approved, leaverequestId) => e => {
    e.preventDefault()
    let approvalData

    if (approved === true) {
      approvalData = {
        is_granted: approved,
        leave_request: leaverequestId,
        reason: ''
      }
    } else {
      approvalData = {
        is_granted: approved,
        leave_request: leaverequestId,
        reason: reasonInput.current.state.innerValue
      }
    }

    props.dispatch(approveLeaveRequest(approvalData))
    if (approved === true) {
      setModal({
        ...modal,
        isapproved: !modal.isapproved
      })
    } else {
      setModal({
        ...modal,
        isdenied: !modal.isdenied
      })
    }
  }

  const today = new Date()

  const lastMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 28)

  const sixMonths = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 182)

  const handledate = (date) => {
    const dates = new Date(date) // 2009-11-10
    const month = dates.toLocaleString('default', { month: 'short' })
    const weekday = dates.toLocaleString('default', { weekday: 'short' })
    return { month: month, weekday: weekday, date: dates.getDate() }
  }

  const handleDateDifference = (date1, date2) => {
    var a = moment(new Date(date1))
    var b = moment(new Date(date2))
    var diffDays = b.diff(a, 'days')
    if (diffDays === 1) {
      return diffDays + ' day'
    } else if (diffDays > 1) {
      return diffDays + ' days'
    } else {
      return 'Less than a day'
    }
  }

  const CalendarWithRange = withRange(Calendar)

  const req = props.leave_request.leave_requests.length

  return (
    <MDBRow className='hq-approve-leave'>
      <MDBCol md='11' className='tab-content'>
        <MDBRow>
          <MDBCol md='6'>
            <h5 className='text-left'>
              Leave Approval
            </h5>
          </MDBCol>
          <MDBCol md='6'>
            <div className='approval-requests text-right'>
              <MDBBadge
                pill
                className='text-right'
                color={req === 0 ? 'light' : 'dark'}
              >
                {
                  req === 0
                    ? 'No leave requests'
                    : req === 1
                      ? req + ' request, needs approval'
                      : req + ' requests, need approval'
                }
              </MDBBadge>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol md='11' className='tab-content leave-approval'>
        {props.leave_request.leave_requests.length > 0 &&
          <MDBContainer>
            <MDBCol sm='12'>
              <MDBRow>
                <MDBCol md='5' className='text-left'>
                  <h6>{
                    props.leave_request.leave_requests[0]
                      ? props.leave_request.leave_requests[0].user.names
                      : ''
                  }
                  </h6>
                </MDBCol>
                <MDBCol md='3' className='text-left'>
                  <h6>
                    {
                      props.leave_request.leave_requests[0]
                        ? props.leave_request.leave_requests[0].type_of_leave.type_name
                        : ''
                    }
                  </h6>
                </MDBCol>
                <MDBCol md='4' className='text-right'>
                  <h6>Who else, will be out?</h6>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol sm='12'>
              <MDBRow>
                <MDBCol md='5'>
                  <MDBRow className='approve-calendar'>
                    <MDBCol md='4' className='profile-pic'>
                      <HQPageAvatar
                        prof_pic={
                          props.leave_request.leave_requests[0]
                            ? props.leave_request.leave_requests[0].user.img_url
                              ? props.leave_request.leave_requests[0].user.img_url.includes('http')
                                ? props.leave_request.leave_requests[0].user.img_url
                                : ''
                              : ''
                            : ''
                        }
                      />
                    </MDBCol>
                    <MDBCol md='8' className='date-range'>
                      <MDBRow>
                        <MDBCol md='5'>
                          <h3 className='calp-date-title text-center'>
                            <div className='calp-month'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_start_date
                                : '02/02/2019').month
                            }
                            </div>
                            <div className='calp-day'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_start_date
                                : '02/02/2019').date
                            }
                            </div>
                            <div className='calp-weekday'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_start_date
                                : '02/02/2019').weekday
                            }
                            </div>
                          </h3>
                        </MDBCol>
                        <MDBCol md='2'>
                          <MDBIcon
                            icon='long-arrow-alt-right'
                          />
                        </MDBCol>
                        <MDBCol md='5'>
                          <h3 className='calp-date-title'>
                            <div className='calp-month'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_end_date
                                : '03/02/2019').month
                            }
                            </div>
                            <div className='calp-day'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_end_date
                                : '03/02/2019').date
                            }
                            </div>
                            <div className='calp-weekday'>{
                              handledate(props.leave_request.leave_requests[0]
                                ? props.leave_request.leave_requests[0].requested_end_date
                                : '03/02/2019').weekday
                            }
                            </div>
                          </h3>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className='leave-reason'>
                    <MDBCol sm='12'>
                      <MDBInput
                        readOnly
                        outline
                        type='textarea'
                        label='Reason for leave request'
                        value={
                          props.leave_request.leave_requests[0]
                            ? props.leave_request.leave_requests[0].reason_for_leave
                            : ''
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md='3' className='leave-details'>
                  <MDBRow className='text-left'>
                    <h6>
                      <b>Requested:</b>&nbsp;
                      {
                        handleDateDifference(
                          props.leave_request.leave_requests[0]
                            ? props.leave_request.leave_requests[0].requested_start_date
                            : '02/02/2019',
                          props.leave_request.leave_requests[0]
                            ? props.leave_request.leave_requests[0].requested_end_date
                            : '03/02/2019'
                        )
                      }
                    </h6>
                    <h6>
                      <b>On:</b>&nbsp;
                      <a href='#!'>{
                        props.leave_request.leave_requests[0]
                          ? props.leave_request.leave_requests[0].daterequested
                          : '02/02/2019'
                      }
                      </a>
                    </h6>
                    <h6>
                      <b>History:</b>&nbsp;
                      {
                        props.leave_request.leave_requests[0]
                          ? props.leave_request.leave_requests[0].type_of_leave.taken_days === 0
                            ? 'Never taken'
                            : props.leave_request.leave_requests[0].type_of_leave.taken_days + ' days(s) taken'
                          : ''
                      }
                    </h6>
                    <h6>
                      <b>Balance:</b>&nbsp;
                      {
                        props.leave_request.leave_requests[0]
                          ? props.leave_request.leave_requests[0].type_of_leave.days_balance === 0
                            ? 'None'
                            : props.leave_request.leave_requests[0].type_of_leave.days_balance === 1
                              ? props.leave_request.leave_requests[0].type_of_leave.days_balance + ' day'
                              : props.leave_request.leave_requests[0].type_of_leave.days_balance + ' days'
                          : ''
                      }
                    </h6>
                  </MDBRow>
                  <MDBRow>
                    {props.leave_request.leave_requests.length > 0 &&
                      <>
                        <MDBBtn
                          color='primary'
                          className='btn-sm'
                          onClick={toggleApproveModal()}
                        >
                          Approve
                        </MDBBtn>
                        <MDBBtn
                          color='danger'
                          className='btn-sm'
                          onClick={toggleDenyModal()}
                        >
                          Deny
                        </MDBBtn>
                      </>}
                  </MDBRow>
                </MDBCol>
                <MDBCol md='4' className='others-on-leave text-right'>
                  {
                    props.leave_request.users_out.length === 0
                      ? <h6>No one.</h6>
                      : props.leave_request.users_out.map(
                        (user, i) =>
                          <div key={i}>
                            <h6><b>{user.user.names}</b></h6>
                            <MDBRow>
                              <MDBCol sm='12' className='text-right'>
                                <h6>
                                  {handledate(user.requested_start_date).month}.&nbsp;
                                  {handledate(user.requested_start_date).date}&nbsp;-&nbsp;
                                </h6>
                                <h6>
                                  {handledate(user.requested_end_date).month}.&nbsp;
                                  {handledate(user.requested_end_date).date}
                                </h6>
                              </MDBCol>
                            </MDBRow>
                          </div>
                      )
                  }
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol sm='12'>
              <InfiniteCalendar
                Component={CalendarWithRange}
                autoFocus={false}
                width={665}
                height={250}
                disabled
                min={lastMonth}
                max={sixMonths}
                selected={{
                  start: new Date(
                    props.leave_request.leave_requests[0]
                      ? props.leave_request.leave_requests[0].requested_start_date
                      : '02-02-2019'
                  ),
                  end: new Date(
                    props.leave_request.leave_requests[0]
                      ? props.leave_request.leave_requests[0].requested_end_date
                      : '03-02-2019'
                  )
                }}
                locale={{
                  headerFormat: 'MMM Do'
                }}
                theme={{
                  selectionColor: '#e0e0e0',
                  textColor: {
                    default: '#333',
                    active: '#fff'
                  },
                  weekdayColor: 'rgb(146, 118, 255)'
                }}
                displayOptions={{
                  layout: 'landscape',
                  showHeader: false
                }}
              />
            </MDBCol>
            <MDBModal
              backdrop
              isOpen={modal.isapproved}
              size='sm'
              className='leave-accepted'
            >
              <MDBModalHeader toggle={toggleApproveModal()}>
                Please confirm...
              </MDBModalHeader>
              <MDBModalBody>
                Click "Yes", to <span className='green-text'>grant</span> this request.
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color='success'
                  size='sm'
                  onClick={
                    handleApprove(
                      true,
                      props.leave_request.leave_requests[0]
                        ? props.leave_request.leave_requests[0].id
                        : '0')
                  }
                >
                  Yes
                </MDBBtn>
                <MDBBtn
                  color='primary'
                  size='sm'
                  onClick={toggleApproveModal()}
                >
                  Cancel
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal
              backdrop
              isOpen={modal.isdenied}
              size='sm'
              className='leave-denied'
            >
              <MDBModalHeader toggle={toggleDenyModal()}>
                Please confirm...
              </MDBModalHeader>
              <MDBModalBody>
                Click "Yes", to <span className='red-text'>deny</span> this request.
                <MDBInput
                  name='reason'
                  label='Any reason?'
                  ref={reasonInput}
                  size='sm'
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color='danger'
                  size='sm'
                  onClick={
                    handleApprove(
                      false,
                      props.leave_request.leave_requests[0]
                        ? props.leave_request.leave_requests[0].id
                        : '0')
                  }
                >
                  Yes
                </MDBBtn>
                <MDBBtn
                  color='primary'
                  size='sm'
                  onClick={toggleDenyModal()}
                >
                  Cancel
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>}
        {props.leave_request.leave_requests.length < 1 &&
          <MDBContainer>
            <img
              src={require('../../../../../../img/leaverequests.png')}
              alt='Snow'
            />
          </MDBContainer>}
      </MDBCol>
    </MDBRow>
  )
}

const mapStateToProps = state => ({ leave_request: state.leave_reducer })

export default connect(mapStateToProps)(HrtabLeavePage)
