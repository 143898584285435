import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import HQPageTemplate from '../../hqPage/components/hqTemplate'

const AboutPageTemplate = ({ children }) => (
  <HQPageTemplate>
    <MDBRow>
      <MDBContainer fluid className='inner px-0 light-theme metrics'>
        <section className='hq-section hq-about'>
          <MDBRow className='main'>
            <MDBCol md='1' />
            <MDBCol md='10'>
              {children}
            </MDBCol>
            <MDBCol md='1' />
          </MDBRow>
        </section>
      </MDBContainer>
    </MDBRow>
  </HQPageTemplate>
);

export default AboutPageTemplate;
