import React from 'react'
import PropTypes from 'prop-types'
import { getDay, addBusinessDays, parseISO } from 'date-fns'
import DatePicker from 'react-datepicker'
import LeaveSelect from '../leaveSelect'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn
} from 'mdbreact'

const RequestLeaveForm = props => {
  // Handles leave request-form UI
  const {
    // formik props
    values: {
      leavetype,
      startDate,
      endDate,
      reason
    },
    dirty,
    errors,
    touched,
    handleReset,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    // passed props
    modal2,
    getId,
    getGender,
    getLeavetype = [],
    daysLeft = {},
    dispatchEvent = f => f,
    toggleModal = f => f
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }
  
  return (
    <MDBModal
      md='12'
      backdrop
      isOpen={modal2}
      id='user-form'
      className='hq-leave'
      toggle={toggleModal(2 + '@' + 0)}
    >
      <form onSubmit={handleSubmit}>
        <MDBModalHeader
          toggle={toggleModal(2 + '@' + 0)}
        >
          Apply for time-off
        </MDBModalHeader>
        <MDBModalBody className='tab-content'>
          <MDBRow>
            <MDBCol sm='12'>
              <LeaveSelect
                // select props
                value={leavetype}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.leavetype}
                touched={touched.leavetype}
                // passed props
                id={getId}
                user_gender={getGender}
                leave={getLeavetype}
                dispatch={dispatchEvent}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='6'>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                minDate={new Date()}
                selected={startDate}
                placeholderText='Start date'
                name='start_date'
                value={
                  (daysLeft.balance === 0 || daysLeft.balance === isNaN)
                    ? '' : parseISO(startDate)
                }
                filterDate={isWeekday}
                id='start-date'
                required
                className={
                  leavetype === null ? 'disabled'
                    : (daysLeft.balance === 0 || daysLeft.balance === isNaN) ? 'disabled'
                      : ''
                }
                onChange={e => setFieldValue('startDate', e)}
              />
              {touched.startDate && errors.startDate &&
                <span className='invalid-feedback'>{errors.startDate}</span>}
            </MDBCol>
            <MDBCol sm='12' md='6'>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                minDate={startDate}
                maxDate={
                  (daysLeft.balance === 0 || daysLeft.balance === isNaN)
                    ? parseISO(startDate)
                    : startDate instanceof Date
                      ? addBusinessDays(parseISO(startDate.toISOString()), daysLeft.balance - 1)
                      : parseISO(startDate)

                }
                selected={endDate}
                placeholderText='End date'
                name='end-date'
                value={
                  (daysLeft.balance === 0 || daysLeft.balance === isNaN)
                    ? '' : parseISO(endDate)
                }
                filterDate={isWeekday}
                id='end-date'
                required
                className={
                  leavetype === null ? 'disabled'
                    : (daysLeft.balance === 0 || daysLeft.balance === isNaN) ? 'disabled'
                      : startDate instanceof Date ? '' : 'disabled'
                }
                onChange={e => setFieldValue('endDate', e)}
              />
              {touched.endDate && errors.endDate &&
                <span className='invalid-feedback'>{errors.endDate}</span>}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12'>
              <MDBInput
                outline
                type='textarea'
                className={
                  leavetype === null ? 'disabled'
                    : (daysLeft.balance === 0 || daysLeft.balance === isNaN) ? 'disabled'
                      : touched.reason && errors.reason ? 'is-invalid'
                        : ''
                }
                label='Reason'
                size='sm'
                name='reason'
                value={
                  (daysLeft.balance === 0 || daysLeft.balance === isNaN)
                    ? '' : reason
                }
                id='reason'
                validate
                onBlur={handleBlur}
                onChange={change.bind(null, 'reason')}
                error={touched.reason && Boolean(errors.reason)}
              />
              {touched.reason && errors.reason &&
                <span className='invalid-feedback'>{errors.reason}</span>}
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            type='submit'
            color='primary'
            size='sm'
            disabled={!isValid || isSubmitting}
            onClick={toggleModal(2 + '@' + 0)}
          >
            {props.reducer_obj.hasLoaded &&
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                  role='status'
                />
                <span>&nbsp;</span>
              </>
            }
            Submit
          </MDBBtn>
          <MDBBtn
            color='danger'
            size='sm'
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            Reset
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  )
}

RequestLeaveForm.propTypes = {
  values: PropTypes.object,
  modal2: PropTypes.bool,
  getId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  getGender: PropTypes.string,
  getLeavetype: PropTypes.array,
  daysLeft: PropTypes.object,
  dispatchEvent: PropTypes.func,
  toggleModal: PropTypes.func
}

export default RequestLeaveForm
