import React from 'react'
import PropTypes from 'prop-types'
import { compareAsc, format, formatDistance, parseISO } from 'date-fns'
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBCol,
  MDBBadge
} from 'mdbreact'

const LeaveRequestsList = ({
  id,
  leaveList = []
}) => {
  const dayTracker = param => formatDistance(
    parseISO(param), new Date(),
    { includeSeconds: true, addSuffix: true }
  )

  return (
    <MDBContainer>
      <h6>Track your leave...</h6>
      <MDBListGroup>
        {leaveList.filter(
          (list) => (list.user.id === id)
        ).map(
          (list, i) =>
            <MDBListGroupItem
              key={i}
              className='d-flex justify-content-between align-items-center'
            >
              <MDBCol md='3'>
                <span className='text-left'><b>{list.type_of_leave.type_name}</b></span>
              </MDBCol>
              <MDBCol md='7'>
                {list.hasresponse
                  ? list.response[0].is_granted
                    ? compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                      ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                        ? 'Starts'
                        : 'Started'
                      : 'Ended,'
                    : 'Not Allowed'
                  : compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                    ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                      ? 'Might start'
                      : 'No response, since'
                    : 'Not taken'}&nbsp;
                <span>
                  <b>
                    {list.hasresponse
                      ? list.response[0].is_granted
                        ? compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                          ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                            ? dayTracker(list.requested_start_date)
                            : dayTracker(list.requested_start_date)
                          : format(parseISO(list.requested_end_date), 'do MMM. yyyy')
                        : ''
                      : compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                        ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                          ? dayTracker(list.requested_start_date)
                          : dayTracker(list.requested_start_date)
                        : ''}
                  </b>
                </span>
              </MDBCol>
              <MDBCol md='2'>
                <MDBBadge
                  className='text-right'
                  color={
                    list.hasresponse
                      ? list.response[0].is_granted
                        ? compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                          ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                            ? 'success'
                            : 'primary'
                          : 'dark'
                        : 'danger'
                      : 'light'
                  }
                  pill
                >
                  {list.hasresponse
                    ? list.response[0].is_granted
                      ? compareAsc(new Date(), parseISO(list.requested_end_date)) === -1 || 0
                        ? compareAsc(new Date(), parseISO(list.requested_start_date)) === -1
                          ? 'Approved'
                          : 'On-going'
                        : 'Finished'
                      : 'Declined'
                    : 'Pending'}
                </MDBBadge>
              </MDBCol>
            </MDBListGroupItem>
        )}
      </MDBListGroup>
    </MDBContainer>
  )
}

LeaveRequestsList.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  leaveList: PropTypes.array
}

export default LeaveRequestsList
