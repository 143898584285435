
const constants = {
  FETCH_USER_BEGIN: 'FETCH_USER_BEGIN',
  FETCH_ALL_USERS: 'FETCH_ALL_USERS',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
  ENABLE_EDIT_USER_DETAILS: 'ENABLE_EDIT_USER_DETAILS',
  LOAD_TAB_MODAL_DIALOG: 'LOAD_TAB_MODAL_DIALOG',
  LOAD_UPDATE_USERNAME_MODAL_DIALOG: 'LOAD_UPDATE_USERNAME_MODAL_DIALOG',
  REDIRECT_TO_LOGIN: 'REDIRECT_TO_LOGIN',
  EDIT_KEY_METRICS: 'EDIT_KEY_METRICS',
  SET_KEY_METRICS_VALUE: 'SET_KEY_METRICS_VALUE',
  FETCH_METRICS: 'FETCH_METRICS',
  FETCH_LEAVE_TYPES: 'FETCH_LEAVE_TYPES',
  FETCH_LEAVE_REQUESTS: 'FETCH_LEAVE_REQUESTS',
  FETCH_USER_LEAVE_REQUESTS: 'FETCH_USER_LEAVE_REQUESTS',
  LOAD_LEAVE_DAYS_BALANCE: 'LOAD_LEAVE_DAYS_BALANCE',
  APPROVE_LEAVE_REQUESTS: 'APPROVE_LEAVE_REQUESTS'
}

export default constants
