import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { MDBRow, MDBCol } from 'mdbreact'
import HrMainPanel from '../components/mainPanel'
import HrBlockPanel from '../components/blockPanel'
import { fetchUser } from '../../../store/dispatchers'

const HrMainPage = ({ ...props }) => {
  useEffect(() => {
    props.dispatch(
      fetchUser(sessionStorage.getItem('token'), sessionStorage.getItem('username'))
    )
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <MDBRow className='mb-3'>
        <MDBCol sm='12' md='11'>
          <HrMainPanel {...props} name={props.user.given_name} />
        </MDBCol>
      </MDBRow>
      <MDBRow className='mb-3'>
        <MDBCol sm='12' md='11'>
          <Route component={HrBlockPanel} />
        </MDBCol>
      </MDBRow>
    </>
  )
}

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HrMainPage)
