import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import HQFooter from '../../../AppFooter';
import HQNavbar from '../../../AppHeader';
import jwt_decode from 'jwt-decode';
import toastr from 'toastr'

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-top-left',
  toastClass: 'toastr'
};

const HQPageTemplate = ({ children }) => {
  const name = sessionStorage.getItem('name');
  const token = sessionStorage.getItem('token');
  let validToken = false

  if (token) {
    const decoded = jwt_decode(token)
    validToken = decoded.sub === name
  }
  return (
    (token && validToken)
      ? (
        <MDBContainer fluid className='px-0'>
          <MDBRow>
            <MDBCol size='12'>
              <HQNavbar />
            </MDBCol>
          </MDBRow>
          {children}
          <MDBRow>
            <MDBCol size='12'>
              <HQFooter />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : (
        // redirect to the login
        <Redirect to='/login' />
      )
  )
}

HQPageTemplate.propTypes = {
  children: PropTypes.any
}

export default HQPageTemplate
