import React from 'react'
import { Route } from 'react-router-dom'
import PageTabs from '../../components/pageTabs'

const ProfileTabsTemplate = ({ match }) => {
  const pagetabs = [
    {
      label: 'Home',
      home: '1',
      url: '/profile/home'
    },
    {
      label: 'Emergency',
      home: '2',
      url: '/profile/emergency-contacts'
    },
    {
      label: 'Job',
      home: '3',
      url: '/profile/job'
    },
    {
      label: 'Documents',
      home: '4',
      url: '/profile/documents'
    },
    {
      label: 'Asset',
      home: '5',
      url: '/profile/assets'
    },
    {
      label: 'Time Off',
      home: '6',
      url: '/profile/time-off'
    },
    {
      label: 'Trainings',
      home: '7',
      url: '/profile/training'
    },
    {
      label: 'Bank Info',
      home: '8',
      url: '/profile/bank-info'
    }
  ]

  return <Route component={() => <PageTabs tab={pagetabs} />} />
}

export default ProfileTabsTemplate
