import React from 'react'
import classNames from 'classnames'
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBNavLink,
  MDBIcon,
  MDBBadge
} from 'mdbreact'

const DetailedCardView = (props) => {
  const {
    handleLoadUpdateDialog = f => f,
    ico,
    iconSmall,
    iconBig,
    projects,
    tag,
    target_value,
    title,
    total,
    average,
    unit_measure,
    update,
    updatedby,
    value,
    participants
  } = props

  const handleColor = (percent) => {
    const classes = classNames(
      {
        'danger-color': Math.floor(0) <= percent && percent <= Math.floor(25),
        'warning-color-dark': Math.floor(26) <= percent && percent <= Math.floor(50),
        'primary-color': Math.floor(51) <= percent && percent <= Math.floor(75),
        'success-color': Math.floor(76) <= percent && percent <= Math.floor(100)
      }
    )

    return classes
  }

  return (
    <MDBCol md='12' xl='12' className='mb-r'>
      <MDBCard className='cascading-admin-card'>
        <MDBCardBody>
          <MDBNavLink to='#!'>
            <MDBRow>
              <MDBCol md='3'>
                <div className='icon-details'>
                  <div
                    className={`
                      icon 
                      icon-shape 
                      ${handleColor(average)} 
                      text-white 
                      rounded-circle 
                      shadow
                    `}
                  >
                    <MDBIcon icon={iconBig} />
                  </div>
                </div>
              </MDBCol>
              <MDBCol md='9'>
                <div className='data-details'>
                  <div className='text-uppercase text-muted'>
                    {title}
                  </div>
                  <span className='h4 font-weight-bol'>
                    {tag === 're' ? unit_measure : ''} {target_value.toLocaleString()} {(tag === 'he' || tag === 're') ? '' : unit_measure}
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <p className='mb-0 text-muted text-sm'>
                  <span className='text-nowrap'>
                    Last Score
                  </span>
                </p>
              </MDBCol>
              <MDBCol>
                <div className='data-details text-sm'>
                  <div className='text-success mb-0'>
                    <p className='badge badge-info'>
                      {(tag === 'tnps' || tag === 'cnps') ? (value===0? value: value/ participants).toFixed(1) : value.toLocaleString()}
                    </p>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
            {(tag === 'tnps' || tag === 'cnps') &&
              <MDBRow>
                <MDBCol>
                  <div className='mb-0 text-muted text-sm texts-aligns'>
                    <div className='text-nowrap'>
                      Participant{participants > 1 ? 's' : ''}
                    </div>
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className='mb-0 data-details text-sm texts-aligns'>
                    <div className='text-success mb-0'>
                      <p className='badge badge-success mb-0'>
                        {participants}
                      </p>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>}
            {projects.length > 0 &&
              <MDBRow>
                <MDBCol>
                  <div className='mb-0 text-muted text-sm texts-aligns'>
                    <div className='text-nowrap'>
                      Metric Project{projects.length > 1 ? 's' : ''}
                    </div>
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className='mb-0 data-details text-sm texts-aligns'>
                    <div className='text-success mb-0'>
                      <p className='badge badge-success mb-0'>
                        {projects.length}
                      </p>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>}
          </MDBNavLink>
          <hr />
          {total > 0 &&
            <MDBRow className='mb-2'>
              <MDBCol>
                <div className='mb-0 text-muted text-sm'>
                  <div className='text-nowrap'>
                    Total
                  </div>
                </div>
              </MDBCol>
              <MDBCol>
                <div className='mb-0 data-details text-sm'>
                  <div className='text-success mb-0'>
                    <MDBBadge color='dark'>
                      {total.toLocaleString()}
                    </MDBBadge>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>}
          {total === 0 &&
            <MDBRow className='empty' />}
          <MDBRow>
            <MDBCol>
              <p className='mt-0 mb-0 text-muted text-sm'>
                <MDBIcon far={ico} icon={iconSmall} /> &nbsp;
                <span className='text-nowrap'>
                  {update}
                </span>
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md='8'>
              <div className='mt-0 mb-0 data-details text-sm'>
                <p className='mb-0 text-muted'>
                  <span className='updater'>{updatedby}</span>
                </p>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              {sessionStorage.getItem('user_group') === 'admin' &&
                <MDBIcon
                  far
                  icon='edit'
                  onClick={handleLoadUpdateDialog(true, props)}
                  className='span-mouse float-right text-muted'
                />}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  )
}

export default DetailedCardView
