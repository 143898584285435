import React from 'react'
import { MDBContainer, MDBCol } from 'mdbreact'

const HQGuides = () => (
  <MDBContainer>
    <MDBCol className='py-3'>
      <h1>[Our Guides]</h1><br />
      <h3>[Employee guide]</h3>
      <h3>[Onboarding guide]</h3>
      <h3>[Departing guide]</h3><br />
      <h1>See others...</h1>
      <h3>[Marketing]</h3>
      <h4>[Our brand guide]</h4>
      <h4>[Customer Relations guide]</h4><br />
      <h3>[Engineering]</h3>
      <h4>[Our Agile model]</h4>
      <h4>[Collaboration]</h4>
    </MDBCol>
  </MDBContainer>
);

export default HQGuides
