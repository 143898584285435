import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DashboardPage from './pages/DashboardPage'
import { Error404 } from '../../AppError'

const Routes = () =>
  <Switch>
    <Route exact path='/about/metrics' component={DashboardPage} />
    <Route component={Error404} />
  </Switch>

export default Routes
