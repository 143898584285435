import React from 'react';
import { MDBCol } from 'mdbreact';
import Routes from './components/Routes';

const Mtracker = () =>
  <MDBCol
    md="12"
    className="flexible-content"
  >
    <main id="content">
      <h2>Metrics Tracker</h2>
      <Routes />
    </main>
  </MDBCol>

export default Mtracker;
