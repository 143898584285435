import React from 'react'
import { MDBContainer, MDBCol } from 'mdbreact'

const HQSkill = () => (
  <MDBContainer>
    <MDBCol className='py-5'>
      <h1>[Human Resource retreat]</h1><br/>
      <h1>See others...</h1>
      <h3>[Marketing conference]</h3>
      <h3>[Engineering bootCamp]</h3>
    </MDBCol>
  </MDBContainer>
);

export default HQSkill;
