import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, updateUser } from '../../../../store/dispatchers'
import { enableEditUserDetails, handleEditUserDetails } from '../../../../store/actions'
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBBtn
} from "mdbreact";

class HQProfileTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSaveUpdate = this.handleSaveUpdate.bind(this);
    this.handleSaveUpdate2 = this.handleSaveUpdate2.bind(this);
  }

  handleSaveUpdate = (event) => {
    event.preventDefault();
    let editted = {
      education: { bachelors: this.props.user.degree },
      completed_courses: {
        course1: this.props.user.courses
      }
    }

    this.props.dispatch(
      updateUser(
        editted, 
        this.props.user.id, 
        this.props.user.isuserupdated, 
        'education', 
        this.props.user.username
      )
    );
  }

  handleSaveUpdate2 = (event) => {
    event.preventDefault();
    let editted = {
      user_group: this.props.user.role
    }
    this.props.dispatch(
      updateUser(
        editted, 
        this.props.user.id, 
        this.props.user.isuserupdated, 
        'role', 
        this.props.user.username
      )
    );
  }

  handleEdit = (event) => {
    event.preventDefault();
    this.props.dispatch(enableEditUserDetails(event.target.name))
  }

  handleCancel = (event) => {
    event.preventDefault();
    this.props.dispatch(fetchUser(sessionStorage.getItem('token'), this.props.user.username));
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.dispatch(handleEditUserDetails(event.target.name, event.target.value))
  }

  render() {
    return (
      <MDBRow>
        <MDBCol md='11' className="tab-content">
          <MDBRow>
            <MDBCol md='10'>
              <h5 className='text-left'>
                {this.props.user.username}
              </h5><hr />
            </MDBCol>
            <MDBCol md='2'>
              <div className="text-right">
                {!this.props.user.edit_courses &&
                  <MDBBtn
                    color="indigo"
                    className="edit-button"
                    name="edit_courses"
                    onClick={this.handleEdit}
                  >
                    Edit
                  </MDBBtn>
                }
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label="Education"
                name="education"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.degree
                      ? this.props.user.degree
                      : '' 
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
            <MDBCol sm='12' md='4'>
              <MDBInput
                label="Courses"
                name="courses"
                onChange={this.handleChange}
                value={
                  this.props.user.user[0]
                    ? this.props.user.courses
                      ? this.props.user.courses
                      : '' 
                    : ''}
                size="sm"
                readOnly={this.props.user.readonly}
              />
            </MDBCol>
          </MDBRow>
          {this.props.user.edit_courses &&
            <MDBRow>
              <MDBCol md="12">
                <div className="text-right">
                  <MDBBtn
                    color="primary"
                    className='action-btn'
                    name="save-course"
                    onClick={this.handleSaveUpdate}
                  >
                    {this.props.user.hasLoaded &&
                      <>
                        <span
                          className='spinner-border spinner-border-sm'
                          aria-hidden='true'
                          role='status'
                        ></span>
                        <span>&nbsp;</span>
                      </>
                    }
                    Save
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className='action-btn'
                    name="cancel-course"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          }
          <hr />
          {this.props.user.isAdmin &&
            <MDBRow>
              <MDBCol md="12">
                <div className="text-right">
                  {!this.props.user.edit_roles &&
                    <MDBBtn
                      color="indigo"
                      className="edit-button"
                      name="edit_roles"
                      onClick={this.handleEdit}
                    >
                      Edit Role
                    </MDBBtn>
                  }
                </div>
              </MDBCol>
            </MDBRow>
          }
          <MDBRow>
            <MDBCol sm='12' md='12'>
              <p>User Group</p>
              <div className="form-group">
                <input
                  type="radio"
                  disabled={this.props.user.readonly}
                  name="role"
                  onChange={this.handleChange}
                  checked={
                    this.props.user.user[0]
                      ? this.props.user.role === "user"
                        ? true
                        : false
                      : false}
                  value="user" /> User<br />
                <input
                  type="radio"
                  disabled={this.props.user.readonly}
                  name="role"
                  onChange={this.handleChange}
                  checked={
                    this.props.user.user[0]
                      ? this.props.user.role === "admin"
                        ? true
                        : false
                      : false}
                  value="admin" /> Admin<br />
                <input
                  type="radio"
                  disabled={this.props.user.readonly}
                  name="role"
                  onChange={this.handleChange}
                  checked={
                    this.props.user.user[0]
                      ? this.props.user.role === "finance"
                        ? true
                        : false
                      : false}
                  value="finance" /> Finance<br />
              </div>
            </MDBCol>
          </MDBRow>
          {this.props.user.edit_roles &&
            <MDBRow>
              <MDBCol md="12">
                <div className="text-right">
                  <MDBBtn
                    color="primary"
                    className='action-btn'
                    name="save-role"
                    onClick={this.handleSaveUpdate2}
                  >
                    {this.props.user.hasLoaded &&
                      <>
                        <span
                          className='spinner-border spinner-border-sm'
                          aria-hidden='true'
                          role='status'
                        ></span>
                        <span>&nbsp;</span>
                      </>
                    }
                      Save
                    </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className='action-btn'
                    name="cancel-role"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          }
          <hr />
          <MDBRow>
            <MDBCol md="6" sm="12">
              <MDBRow>
                <MDBCol md="10">
                  <p>Growth Plan</p>
                </MDBCol>
                <MDBCol md="2">
                  <a href="#!"><MDBIcon title="Add New Growth Plan" icon="plus-circle" /></a>
                </MDBCol>
              </MDBRow>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>#</th>
                    <th>Growth Plan</th>
                    <th>Details</th>
                    <th>Update</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {this.props.user.user[0] ? this.props.user.growth_plan.map((plan, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{plan}</td>
                      <td className="text-center">
                        <MDBIcon icon="th-list" />
                      </td>
                      <td >
                        <MDBIcon icon="pencil-alt" title="Edit Growth Plan" />&nbsp;&nbsp;&nbsp;
                        <MDBIcon className="red-text" title="Delete Growth Plan" far icon="trash-alt" />
                      </td>
                    </tr>
                  ))
                    : <tr></tr>}
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
            <MDBCol md="6" sm="12">
              <MDBRow>
                <MDBCol md="10">
                  <p>Events</p>
                </MDBCol>
                <MDBCol md="2">
                  <a href="#!"><MDBIcon title="Add New Event" icon="plus-circle" /></a>
                </MDBCol>
              </MDBRow>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>#</th>
                    <th>Events</th>
                    <th>Details</th>
                    <th>Update</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {this.props.user.user[0]
                    ? this.props.user.events.map(
                      (event, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{event}</td>
                          <td className="text-center">
                            <MDBIcon icon="th-list" />
                          </td>
                          <td >
                            <MDBIcon icon="pencil-alt" title="Edit Event" />&nbsp;&nbsp;&nbsp;
                            <MDBIcon className="text-danger" title="Delete Event" far icon="trash-alt" />
                          </td>
                        </tr>
                      ))
                    : <tr></tr>}
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    )
  }
};

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HQProfileTraining);
