import React from 'react'
import classNames from 'classnames'
import { MDBCol, MDBInput } from 'mdbreact'

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <MDBCol sm='6' md='6' className='d-inline'>
      <MDBInput
        name={name}
        id={id}
        type='radio'
        value={id}
        label={label}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        filled
        className={classNames('radio-button')}
        {...props}
      />
    </MDBCol>
  )
}
