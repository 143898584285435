import React from 'react'
import { Redirect } from 'react-router-dom'
import toastr from 'toastr'
import Cookies from 'universal-cookie'
import jwt_decode from 'jwt-decode'
import { 
  MDBCard, 
  MDBCardImage, 
  MDBCardBody, 
  MDBContainer, 
  MDBRow, 
  MDBCol ,
  MDBInput,
  MDBBtn,
  MDBNavLink
} from 'mdbreact'

toastr.options = {
  "closeButton": true,
  "progressBar": true,
  "positionClass": "toast-top-left",
  "toastClass": "toastr"
};

class HQAuth extends React.Component {
  /**
   * redirectToReferrer is set to false because by defaut we are sent to the login page
   * @param  object         props
   */
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      email: "",
      value: ""
    }
    this.login = this.login.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.checkPinSize = this.checkPinSize.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    this.handleBackground()
    if (this.props.location) {
     
      this.setState({
        email: this.props.location.state.email
      })
    }
  };

  handleBackground() {
    let root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', 'sky-theme')
  };



  /**
   * This funtion calls the kanzuHqAuth's authenticate function which takes in a call back function that
   * sets the state's redirectToReferrer to true hence sending to the page the user was trying to access.
   * @return Boolean  redirectToReferrer this is an object key from the state object that once set to true redirects a page
   */
  login() {
    return kanzuHqAuth.authenticate(() => {
      this.setState({
        redirectToReferrer: true,
      })
    })
  }

  /**
   * This is the function that is supposed to verify with the backend that the pin is correct
   * and will therefore return the approriate error message or login status and set a cookie
   * for the current user.
   */
  
  handleLogin() {
        const cookies = new Cookies();
        cookies.set(this.state.email, this.state.email, { path: '/'});
        let response ="logged in successfully"
        toastr.success(response)
        this.login()
      
  }

  /**
   * This function for now returns a string error but is to be 
   * connected to the backend API that acctually
   * does the resend functionality
   * @return string message This is a message that is sent to
   *  the user to alert them that the pin has been sent
   */
  resendCode (){
    const message = "A new pin has been sent to " + this.state.email + ". Please check your email to confirm.";
    return toastr.success(message)
  }
  /**
   * This function sets the state value to equal to the current input field value
   * @param  {[type]} event [description]
   * @return {[type]}       [description]
   */
  myChangeHandler = (event) => {
    return this.setState({value: event.target.value});
  }
  /**
   * [checkPinSize description]
   * @param  object    event             Event triggered on submit
   * @return function  handlelogin       Function called when the pinSize is ok
   * @return string     error            String returned when error is incorrect
   */
  checkPinSize(event){
    event.preventDefault();
    let pinSize = this.state.value;
    if (pinSize.length === 6){
      return this.handleLogin();
    }else{
      const error = "Incorrect pin! Please try again."
      return toastr.error(error);
    }
  }

  render() {
    /**
     * This statement redirects a user to the protected page
     * (the page they want to access but only logged users can) they were trying
     *  to access,before they logged in, after they have logged in.
     *   this statement is only executed when redirectToReferrer to true
    */

    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return (
        <Redirect to={'/'} />
      )
    }
    
    /**
     * this returns a google login component from the react google login package
     * Urgent:remember to add the clientID as a variable from .env file for safety reasons.
     */
    let name = sessionStorage.getItem('name');
    let token = sessionStorage.getItem('token');
    let validToken = false;
    const error = "Access Denied, Please Login";
    if (token) {
      let decoded = jwt_decode(token);
      validToken = decoded.sub === name;
    }
    
  return(
    (token && validToken) ?
    (
      <MDBContainer fluid className='hq-login sky-theme'>
        <MDBRow>
          <MDBCol sm='12' className='sky-theme'>
            <MDBCard reverse className='sky-theme'>
              <MDBCardImage cascade
                className='main-image d-none' />
              <MDBCardBody cascade className="text-center hq-auth">
                <h1 className="indigo-text main-logo">
                  <strong>KanzuHQ</strong>
                </h1>
                <h3 className="main-message">Our Online Headquarters</h3>
                <form className="needs-validation" onSubmit={this.checkPinSize} noValidate >
                  <MDBRow>
                  <MDBCol md="4">
                   </MDBCol>
                    <MDBCol md="4">
                      <MDBInput  value={this.state.value}  name="pinCode"  type="number" onChange={this.myChangeHandler}
                        label="Random pin code of six numbers" outline size="lg" required >
                        <div className="valid-feedback">Looks good!</div>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                   </MDBCol>
                  </MDBRow>

                   <MDBBtn color="primary" onClick={this.resendCode}>
                    Resend code
                  </MDBBtn>     
                  <MDBBtn color="success" type="submit">
                    Submit
                  </MDBBtn>
                </form>              
                <h6 className="help-text">
                   <p >
                    If <span className="brown-text"><b>{this.state.email}</b></span>  is not your email? 
                      <MDBNavLink to='/login'>
                        Go back to login
                       </MDBNavLink>
                       </p>
                </h6>
                <h6 className="end-text">
                  KanzuHQ is a product of <a href="https://kanzucode.com" target="_blank" rel="noopener noreferrer">Kanzu Code</a>
                </h6>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      ) : (
      // redirect to the login
      toastr.error(error),
      <Redirect to="/login" />
      )
    );
  }
};

export const kanzuHqAuth = {

  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true
    /*Wait 100 milliseconds to set redirrectTorefferer to true*/
    setTimeout(cb, 100)
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
};

export default HQAuth;
