import C from './constants'

// User Management Action-creators

// initialize fetch user details loading
export const fetchUserBegin = () => ({
  type: C.FETCH_USER_BEGIN
})

// fetch all system user or team
export const fetchAllUsers = param => ({
  type: C.FETCH_ALL_USERS,
  payload: { param }
})

// Success in fetching user details.
export const fetchUserSuccess = param => ({
  type: C.FETCH_USER_SUCCESS,
  payload: { param }
})

// Error in fetching user details.
export const fetchUserFailure = error => ({
  type: C.FETCH_USER_FAILURE,
  payload: { error }
})

// pass target user input field details to edit.
export const handleEditUserDetails = (param1, param2) => ({
  type: C.EDIT_USER_DETAILS,
  payload: { param1, param2 }
})

// change user details to update mode
export const enableEditUserDetails = param => ({
  type: C.ENABLE_EDIT_USER_DETAILS,
  payload: { param }
})

// Modal handler Action-creators

// handle loading of modal dialogs
export const loadmodaldialog = (param, param1, param2) => ({
  type: C.LOAD_TAB_MODAL_DIALOG,
  payload: { param, param1, param2 }
})

// load user timeout dialog on updating username
export const updateUserUsernameModal = () => ({
  type: C.LOAD_UPDATE_USERNAME_MODAL_DIALOG
})

// make user redirected to login after updating his username.
export const redirectUserToLoginAgain = () => ({
  type: C.REDIRECT_TO_LOGIN
})

// Metrics Action Creators

// pass an updated metric values.
export const handleEditMetricValues = (param1, param2) => ({
  type: C.EDIT_KEY_METRICS,
  payload: { param1, param2 }
})

// set the metric initial state new value
export const handleUpdateMetricInitialValue = (param1, param2) => ({
  type: C.SET_KEY_METRICS_VALUE,
  payload: { param1, param2 }
})

export const loadMetrics = (param, param1) => ({
  type: C.FETCH_METRICS,
  payload: { param, param1 }
})

// Leave Management Action Creators

export const loadLeaveTypes = (param, param1) => ({
  type: C.FETCH_LEAVE_TYPES,
  payload: { param, param1 }
})

// set leave initial state data.
export const loadLeaveRequests = (param, param1) => ({
  type: C.FETCH_LEAVE_REQUESTS,
  payload: { param, param1 }
})

export const loadUserLeaveRequests = (param, param1) => ({
  type: C.FETCH_USER_LEAVE_REQUESTS,
  payload: { param, param1 }
})

export const loadLeaveDays = (param, param1) => ({
  type: C.LOAD_LEAVE_DAYS_BALANCE,
  payload: { param, param1 }
})
