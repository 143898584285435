import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import HQPageTemplate from '../../hqPage/components/hqTemplate'
import { HQPageAvatar } from '../../hqPage/components/hqAvatar'
import { connect } from 'react-redux'
import { fetchUser } from '../../../store/dispatchers'
import {
  MDBRow,
  MDBCol,
  MDBCardText,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardImage
} from 'mdbreact'

const HQProfileTemplate = ({ children, ...props }) => {
  useEffect(() => {
    props.dispatch(
      fetchUser(sessionStorage.getItem('token'), sessionStorage.getItem('username'))
    )
    // eslint-disable-next-line
  }, [])

  return (
    <HQPageTemplate>
      <section className='hq-section hq-profile light-theme'>
        <MDBRow className='main'>
          <MDBCol md='1' />
          <MDBCol md='3' className='hq-content-1 hq-profile'>
            <MDBRow className='mb-3'>
              <MDBCol sm='12' md='2' className='d-none pr-0' />
              <MDBCol sm='12' md='10'>
                <MDBCard className='hq-card'>
                  <MDBCardImage
                    className='img-fluid'
                    src='https://mdbootstrap.com/img/Photos/Others/images/43.jpg'
                    waves
                  />
                  <HQPageAvatar
                    prof_pic={
                      props.user.user[0]
                        ? props.user.pic_url
                          ? props.user.pic_url.includes('http')
                            ? props.user.pic_url
                            : ''
                          : ''
                        : ''
                    }
                  />
                  <MDBCardBody>
                    <MDBCardTitle className='text-center'>
                      {
                        props.user.user[0]
                          ? (props.user.given_name + ' ' +
                              props.user.middle_name + ' ' +
                                props.user.surname)
                          : ''
                      }
                    </MDBCardTitle>
                    <MDBCardText>
                      username:&nbsp;
                      <a href='#!'>
                        {props.user.user[0] ? props.user.username : ''}
                      </a><br />
                      Created By:&nbsp;
                      <a href='#!'>
                        {props.user.user[0] ? props.user.createdBy : ''}
                      </a><br />
                      Created On:&nbsp;
                      <a href='#!'>
                        {props.user.user[0] ? props.user.createdDate : ''}
                      </a>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md='7' className='hq-content-2 hq-profile'>
            {children}
          </MDBCol>
          <MDBCol md='1' />
        </MDBRow>
      </section>
    </HQPageTemplate>
  )
}

HQProfileTemplate.propTypes = {
  children: PropTypes.any
}

const mapStateToProps = state => ({ user: state.user_profile_reducer })

export default connect(mapStateToProps)(HQProfileTemplate)
