import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { userReducer, modalReducer, metricsReducer, leaveReducer } from './reducers'

// use combineReducer to combine all store reducers.
const hqRootReducer = combineReducers({
  user_profile_reducer: userReducer,
  modal_reducer: modalReducer,
  metrics_reducer: metricsReducer,
  leave_reducer: leaveReducer
})

// applyMiddleware takes each piece of middleware as a new argument, comma separeted
// eg.const createStoreWithMiddleware = applyMiddleware(ReduxThunk, logger);
export const store = createStore(hqRootReducer, applyMiddleware(thunk))
