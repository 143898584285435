import React from 'react'
import { Route } from 'react-router-dom'
import HrTabsTemplate from '../components/hrTabsTemplate'
import { HrLeave } from '../LeaveMgt'
import HQReports from '../../../Reports'
import HQSkill from '../../../Training'
import HQGuides from '../../../Guide'

const HrOtherPages = ({ match }) =>
  // Defined route for components that require inner-tabs menu
  <Route
    path={`${match.path}/:tabName`}
    render={(props) => (
      <>
        <HrTabsTemplate />
        {
          props.match.params.tabName === 'request-time'
            ? <HrLeave />
            : props.match.params.tabName === 'reports'
              ? <HQReports />
              : props.match.params.tabName === 'training'
                ? <HQSkill />
                : props.match.params.tabName === 'guides'
                  ? <HQGuides />
                  : null
        }
      </>
    )}
  />

export default HrOtherPages
