import React from 'react'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText
} from 'mdbreact'

const HQCard = () => {
  return (
    <MDBCard className='hq-card mb-4'>
      <MDBCardImage className='img-fluid' src='https://mdbootstrap.com/img/Photos/Others/images/43.jpg' waves />
      <MDBCardBody>
        <MDBCardTitle>Mrs. XXX</MDBCardTitle>
        <MDBCardText>
            Management
        </MDBCardText>
        <MDBBtn href='#'>Read More</MDBBtn>
      </MDBCardBody>
    </MDBCard>
  )
};

export default HQCard;
