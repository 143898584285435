import HQHome from './AppMain/hqPage'
import HrHome from './AppMain/hrPage'
import HQAbout from './AppMain/AboutPage'
import { HQProfileHome } from './AppMain/hqProfile'
import { HQLogin, HQAuth, HQLogout } from './AppAuth'

// declare main components here,
// as exported to App.js
export const Login = HQLogin
export const Logout = HQLogout
export const Home = HQHome
export const Auth = HQAuth
export const About = HQAbout
export const Hr = HrHome
export const Profile = HQProfileHome

// error components are exported directly
